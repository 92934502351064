import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import { ref } from 'vue'
import axios from 'axios';

export const userStore = defineStore('user', {
  state: () => {
    return { 
        user: useLocalStorage('user', ''),
        isAuthenticated: useLocalStorage('isAuthenticated', false),
        isContactView: ref(false),
        subscribed: useLocalStorage('subscribed', 0),
        hasPayment: useLocalStorage('hasPayment', false),
        errorMessage: ref(false),
    }
  },
  actions: {
    async login() {
      try {
        const { data } = await axios.get('/get-current-user');
        if (data.email) {
          this.user = data.email;
          this.userIsAdmin = data.isAdmin;
          this.isAuthenticated = true;
          return true
        }
      } catch (e) {
        console.log("error in login " + e)
      }
      return false
    },
    async setSubscriptionStatus() {
      try {
        const validSubscription = (await axios.get('/validate-subscription')).data;
        if(validSubscription){
          const currentTime = new Date().getTime();
          this.subscribed = currentTime;
        }
        else{
          this.subscribed = 0;
        }
      }
      catch (e) {
        console.log(e);
      }
    },
    async checkPaymentMethod() {
      try{
        const validPaymentMethod = (await axios.get('/validate-payment-method')).data;
        this.hasPayment = validPaymentMethod;
      } catch (e) {
        console.log(e);
      }
    },
    logout() {
      try {
        axios.post('/logout').finally(() => {
          this.user = '';
          this.isAuthenticated = false;
          this.subscribed = 0;
          this.hasPayment = false;
          this.$router.push("/");
          // document.cookie = '.AspNetCore.Identity.Application=null; Path=/; Expires=Thu, 01 Jan 2025 00:00:01 GMT;'; // I don't think this works yet
        });
      } catch (e) {
        console.log(e);
      }
    },
  }
})