<script setup>
import RelayButton from './RelayButton.vue';
import { ref, reactive, defineEmits, onMounted } from 'vue';
import axios from 'axios';

const emit = defineEmits(['update', 'invalidContractorInfo'])

const dialog = ref(false);
const newReferral = reactive({
    affiliateId: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
})
const affiliates = ref([]);
const defaultAffiliateTitle = "No Affiliate";

onMounted(async () => {
    try {
        const { data } = await axios.get('/get-affiliate-dropdown');
        affiliates.value = data.affiliates.map(affiliate => ({
            title: affiliate.fullName,
            value: affiliate.relayCode
        }))
        //Add the no affiliates option
        affiliates.value.push({
            title: defaultAffiliateTitle,
            value: null
        });

        if (affiliates.value.length > 0) {
            newReferral.affiliateId = affiliates.value.find(a => a.title === defaultAffiliateTitle).value;
        }
    } catch (e) {
        console.log(e);
    }
});

async function verifyValidContractorInfo() {
    const companyDetailsStatus = (await axios.get("/validate-contractor-info")).data;
    return companyDetailsStatus;
}

async function handleButtonClick() {
  const companyDetailsStatus = await verifyValidContractorInfo();
  if (companyDetailsStatus) {
    dialog.value = true; // Only open the dialog if valid
  } else {
    emit('invalidContractorInfo', true); // Emit the event if invalid
  }
}

function onSubmit() {
    axios.post('/referral/create', newReferral)
        .finally(() => {
            emit('update');
        })
}</script>

<template>
<RelayButton class="button" v-bind="props" @click="handleButtonClick">Create New Referral</RelayButton>
<v-dialog v-model="dialog" width="512">
    <v-card>
        <v-card-title>
        <span class="text-h5">Create New Referral</span>
        </v-card-title>
        <v-card-text>
        <v-container>
            <v-row>
                <v-text-field label="First Name" persistent required v-model="newReferral.firstName"></v-text-field>
                <v-text-field label="Last Name" persistent required v-model="newReferral.lastName"></v-text-field>
            </v-row>
            <v-row>
                <v-text-field label="Email" persistent required v-model="newReferral.email"></v-text-field>
            </v-row>
            <v-row>
                <v-text-field label="Phone" persistent v-model="newReferral.phone" type="tel"></v-text-field>
            </v-row>
            <v-row>
                <v-select
                    :items="affiliates"
                    label="Affiliate"
                    required
                    v-model="newReferral.affiliateId"
                    ></v-select>
            </v-row>
        </v-container>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue-darken-1"
            @click="dialog=false">Cancel</v-btn>
        <RelayButton @click="dialog=false; onSubmit()" msg="Submit" />
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<style scoped>
    .button {
        width: fit-content;
    }
</style>