<script setup>
import RelayButton from './RelayButton.vue';
import { ref, reactive, defineEmits, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

const emit = defineEmits(['update', 'invalidContractorInfo']);
const router = useRouter();
const dialog = ref(false);
const newAffiliate = reactive({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    rewardValue: null,
});

const loading = ref(false);

const alertMessage = ref("");
const alert = ref(false);
const companyAlert = ref(false);
const minValue = 5;
const maxValue = 2000;

const duplicateAffiliateExceptionType = "DuplicateAffiliateException";

onMounted(async () => {
    try {
        const { data } = await axios.get('/get-default-reward-amount');
        newAffiliate.rewardValue = data.defaultRewardAmount;
    } catch (e) {
        console.log(e);
    }
});

const navigateToCompany = async () => {
    router.push('/dashboard/company');
}

async function verifyValidContractorInfo() {
    const companyDetailsStatus = (await axios.get("/validate-contractor-info")).data;
    return companyDetailsStatus;
}

async function handleButtonClick() {
  const companyDetailsStatus = await verifyValidContractorInfo();
  if (companyDetailsStatus) {
    dialog.value = true; // Only open the dialog if valid
  } else {
    emit('invalidContractorInfo', true); // Emit the event if invalid
  }
}

async function onSubmit() {
    loading.value = true;
    try{
        await axios.post('/affiliate/create', newAffiliate);
        loading.value = false;
        alert.value = false;
        dialog.value = false;
        emit('update');
    } catch(error){
        if (error.response && error.response.data && error.response.data.userErrorMessage){
            alertMessage.value = error.response.data.userErrorMessage;
            alert.value = true;
            loading.value = false;
        }
        else if(newAffiliate.rewardValue > maxValue || newAffiliate.rewardValue < minValue){
            alertMessage.value = "Reward amount must be between $5 and $2000.";
            alert.value = true;
            loading.value = false;
        }
        console.log(error);
    }
}
</script>

<template>
<RelayButton class="button" v-bind="props" @click="handleButtonClick">Create New Affiliate</RelayButton>
<v-dialog v-model="dialog" width="512">
    <v-card>
        <v-card-title>
        <span class="text-h5">Create Affiliate</span>
        </v-card-title>
        <v-card-subtitle class="subtitle">
            Creating a new affiliate generates a custom link and sends an invite email and/or SMS to the affiliate allowing them to share your business and earn rewards.
        </v-card-subtitle>
        <v-card-text>
        <v-container>
            <v-row>
                <v-text-field label="First Name" persistent v-model="newAffiliate.firstName"></v-text-field>
                <v-text-field label="Last Name" persistent v-model="newAffiliate.lastName"></v-text-field>
            </v-row>
            <v-row>
                <v-text-field label="Email" persistent v-model="newAffiliate.email"></v-text-field>
            </v-row>
            <v-row>
                <v-text-field label="Phone" persistent v-model="newAffiliate.phone" type="tel"></v-text-field>
            </v-row>
            <v-row class="subtitle-text">
              Enter email address to send affiliate link via email. Enter Phone number to send affiliate link via text. Enter both to send via email and text.
            </v-row>
            <v-row>
                <v-text-field 
                    label="Reward Amount" 
                    hint="Rewards must be between $5-$2000"
                    persistent 
                    v-model="newAffiliate.rewardValue" 
                    type="number"></v-text-field>
            </v-row>
            <v-alert
                v-model="alert"
                :text="alertMessage"
                title="Error creating affiliate"
                type="error"
                ></v-alert>
        </v-container>
        </v-card-text>
        <v-card-actions v-if="!loading">
        <v-spacer></v-spacer>
        <v-btn
            color="blue-darken-1"
            @click="dialog=false">Cancel</v-btn>
        <RelayButton @click="onSubmit()" msg="Submit" />
        </v-card-actions>
        <v-card-actions v-else>
                <v-spacer></v-spacer>
                <v-progress-circular
                    color="primary"
                    class="text-right"
                    indeterminate
                    ></v-progress-circular>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<style scoped>
    .button {
        width: fit-content;
    }
    .subtitle {
        white-space: normal;
    }
    .route-link{
        text-decoration: underline;
        cursor: pointer;
    }
    .subtitle-text {
        font-size: 0.875rem; /* Adjust the font size as needed */
        color: rgba(0, 0, 0, 0.6); /* Adjust the color as needed */
        padding-bottom: 1em;
    }
</style>