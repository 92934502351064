export const StripePublicKey = () => {
    const stripeProductionPublicKey = "pk_live_51P88fmAkuExMd76nymWmB84nILE2Tx5FO8oKroT6Srq38mgOoDjfS8RwwwLdPd1baTmZAi0zmmpl26gBu6uMzVd700yVwI53dJ";
    const stripeDevelopmentPublicKey = "pk_test_51OSEzPJo89PN593AsD0cxRcF4CZ5sWwPnTlNrzRzUgE1Klon6HWKab7IaYhkreuTmJuE7Soozht0wZhfXmdzjgAq00GSWYaDuA"
    const environment = process.env.NODE_ENV;
    console.log(environment);
    if(environment === "production"){
        return stripeProductionPublicKey;
    }
    else{
        return stripeDevelopmentPublicKey;
    }
}