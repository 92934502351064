<script setup>
import axios from 'axios';
import { ref, onMounted } from 'vue'

const data = ref({})
const relayCode = ref(null);

onMounted(() => {
  const path = window.location.pathname.split('/');
  relayCode.value = path.splice(-1)[0];

  axios.get('/relay', {
    params: {
      relayCode: relayCode.value
    }
  }).then((response) => {
    data.value = response.data;
    console.log(response.data);
  })
})

const firstName = ref(null);
const lastName = ref(null);
const email = ref(null);
const phone = ref('');

const loading = ref(false)
const submitSuccess = ref(false);

const onSubmit = async () => {
  loading.value = true;
  const response = await axios.post('/relay/submit',
    {
      "firstName": firstName.value,
      "lastName": lastName.value,
      "email": email.value,
      "phone": phone.value,
      "relayCode": relayCode.value,
    }).finally(() => {
      firstName.value = '';
      lastName.value = '';
      email.value = '';
      phone.value = '';
      loading.value = false;
    });

  submitSuccess.value = response.status === 200;
}
</script>

<template>
  <v-app-bar class="nav" flat density="prominent" color="#262262">
    <router-link to="/"><img alt="Relay Logo" class="logo" src="../assets/images/dashboard-logo.png"
        width="180px" /></router-link>
  </v-app-bar>
  <v-container class="business-card">
    <v-card :class="['elevation-4']" max-width="1024">
      <v-container class="business-card__container">
        <v-row>
          <v-col class="business-card__company-column">
            <v-img width="150" :height="150" aspect-ratio="1/1" :src="data.logoPath"></v-img>
            <h1>{{ data.name }}</h1>
            {{ data.description }}
          </v-col>
          <div class="divider"></div>
          <v-col>
            <v-card flat v-if="!submitSuccess">
              <v-card-title>Contact Information</v-card-title>
              <v-card-subtitle style="white-space: break-spaces;">Enter your information to receive the company contact details!</v-card-subtitle>
              <v-container>
                <v-row>
                  <h3>Name <i>- Required</i></h3>
                </v-row>
                <v-row>
                  <v-text-field v-model="firstName" density="compact" label="First Name"></v-text-field>
                  <v-text-field v-model="lastName" density="compact" label="Last Name"></v-text-field>
                </v-row>
                <v-row>
                  <h3>Email</h3>
                </v-row>
                <v-row>
                  <v-text-field v-model="email" density="compact"></v-text-field>
                </v-row>
                <v-row>
                  <h3>Phone</h3>
                </v-row>
                <v-row>
                  <v-text-field v-model="phone" density="compact"></v-text-field>
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-skeleton-loader height="35px" width="90px" :loading="loading">
                    <v-btn variant='flat' color='#00AEEF' @click="onSubmit">
                      Submit
                    </v-btn>
                  </v-skeleton-loader>
                </v-row>
              </v-container>
            </v-card>
            <v-card flat v-else>
              <v-card-title>Thanks for submitting!</v-card-title>
              <v-card-subtitle style="white-space: break-spaces;">You'll hear from us soon but feel free to reach out!</v-card-subtitle>
              <v-card-text>
                <h2>{{ data.name }}</h2>
                <p>{{ data.website }}</p>
                <p>{{ data.email }}</p>
                <p>{{ data.phone }}</p>
                <a v-if="data.linkedIn" :href="data.linkedIn">
                  <p>LinkedIn</p>
                </a>
                <a v-if="data.instagram" :href="data.instagram">
                  <p>Instagram</p>
                </a>
                <a v-if="data.facebook" :href="data.facebook">
                  <p>Facebook</p>
                </a>
                <a v-if="data.twitter" :href="twitter">
                  <p>Twitter</p>
                </a>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<style scoped>
:deep(.v-toolbar--density-prominent),
:deep(.v-toolbar__content) {
  align-items: center !important;
}

.business-card {
  padding: 4rem;
}

.business-card__container {
  padding: 3rem;
}

.nav {
  display: flex;
  align-items: center;
}

.divider {
  border-radius: 4px;
  width: 4px;
  background-color: #161616;
  margin: 0 1rem;
}

i {
  color: #DA1E28;
}

h3 {
  font-size: 12px;
}

@media only screen and (max-width: 1280px) {
  .divider {
    display: none;
  }

  .business-card {
    padding: 1rem;
  }
}

@media only screen and (max-width: 800px) {
  .business-card__company-column {
    border-bottom: #161616 solid 2px;
  }

  .business-card__container {
    padding: 2rem;
  }

  h1 {
    line-height: 1;
    padding: 1rem 0;
  }

  .divider {
    display: none;
  }
}
</style>