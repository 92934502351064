<script setup>
import HomeNavigation from './components/HomeNavigation.vue'
import ContactButton from './components/ContactButton.vue'
import RelayFooter from './components/RelayFooter.vue'
import { userStore } from './scripts/store'

const store = userStore();
</script>

<template>
  <v-app>
    <v-main class="bg-gray">
      <home-navigation v-if="!$route.path.includes('/dashboard') && !$route.path.includes('r-')" />

      <router-view></router-view>

      <contact-button v-if="!store.isCleanView || store.isContactView" />
    </v-main>

    <footer v-if="!store.isCleanView && !$route.path.includes('/dashboard')">
        <relay-footer class="footer"/>
    </footer>

    <v-snackbar
      v-model="store.errorMessage"
      :timeout="2000"
    >
      {{ store.errorMessage }}

      <template v-slot:actions>
        <v-btn
          color="blue"
          variant="text"
          @click="store.errorMessage = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<style>
#app {
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #12102D;
  background-color: #F4F4F4;
}

.material-symbols-outlined {
  vertical-align: text-top;
}

.bg-gray {
  background-color: #F4F4F4;
}

.bg-relay-blue {
  background-color: #262262;
}

.bg-relay-light-blue {
  background-color: #00688F;
  color: white;
}

body {
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
  align-self: end;
  width: 100%;
}
</style>