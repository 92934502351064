<script setup>
    import { ref, watch, defineProps, defineEmits } from 'vue';

    const props = defineProps({
        onboardingModal: Boolean,
        required: true,
    });

    const emit = defineEmits(['updateModal']);

    const showModal = ref(props.onboardingModal);

    watch(() => props.onboardingModal, (newVal) => {
        showModal.value = newVal;
    });

    watch(() => showModal.value, (update) => {
        if (update == false){
            emit('updateModal', update); // Emit event when dialog is closed
        };
    });
    
</script>

<template>
  <div>
    <v-dialog v-model="showModal" max-width="500px" @update:showModal="updateModal">
      <template v-slot:activator="{ on }"></template> <!-- No activator button -->
      <v-card>
        <v-card-title class="onboarding-title">
            Welcome to Relay!
        </v-card-title>
        <v-card-text>
            <p class="onboarding-text" style="text-align: center;">
                We recommend watching this short video to learn how your back office works.
            </p>
            <div style="position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; height: auto;">
                <iframe 
                    class="relay-video"
                    src="https://www.youtube.com/embed/q1olTy_S9hQ?si=DGpPigNX45NAqPl_"
                    title="YouTube video player"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                </iframe>
            </div>
        </v-card-text>
        <v-card-actions class="align-right">
          <v-btn @click="showModal=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>

<style scoped>
    .button-container {
        display: flex;
        gap: 5px; /* Adjust the gap as needed */
        padding-top: 15px;
    }
    .font-weight-bold{
        font-weight: bold;
    }
    .onboarding-text{
        margin-bottom: 1em; /* Adjust as needed for desired spacing */
    }
    .onboarding-title{
        text-align: center;
    }
    .align-right {
        display: flex;
        justify-content: flex-end;
    }

    /* Mobile view styles */
    @media (max-width: 600px) {
        .button-container {
            flex-direction: column; /* Stack buttons vertically on small screens */
            align-items: center; /* Center buttons horizontally in column layout */
        }
    }
</style>