<script setup>
import { ref } from 'vue';
import axios from 'axios';

import RelayButton from '@/components/RelayButton.vue';

const subjectOptions = Object.freeze([
  `I haven't gotten my reward`,
  `I have a suggestion for the website`,
  `Other`,
]);

const isValidForm = ref(false);

const rules = Object.freeze({
  required: value => !!value || `Required`,
  email: value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || `Invalid e-mail.`
  },
});

const emailBody = ref('');
const emailSubject = ref('');
const fromEmail = ref('');

const isLoading = ref(false);
const showSuccess = ref(false);

const sendEmail = async () => {
  isLoading.value = true;
  showSuccess.value = false;

  try {
    const { data } = await axios.post('/relay/contact-us', undefined, {
      params: {
        emailBody: emailBody.value + ` -- from: ${fromEmail.value}`,
        emailSubject: emailSubject.value,
      },
    });

    showSuccess.value = data.success;
    emailBody.value = '';
    emailSubject.value = '';
    fromEmail.value = '';
  } finally {
    isLoading.value = false;
  }
};
</script>

<template>
  <v-container class="contact-page">
    <v-alert v-model="showSuccess" type="success" title="Success!" text="Your message has been sent!" closable
      close-label="Dismiss" />
    <v-card flat class="contact-page">
      <v-card-title>
        <h1>Contact Us</h1>
      </v-card-title>
      <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <v-form v-model="isValidForm" class="contact-page__form">
              <v-text-field label="Your Email Address*" v-model="fromEmail" :rules="[rules.required, rules.email]" required />
              <v-select label="Subject*" v-model="emailSubject" :items="subjectOptions" variant="outlined"
                hint="Select your reason for reaching out" persistent-hint :rules="[rules.required]" required />
              <v-textarea label="Message*" v-model="emailBody" :rules="[rules.required]" required />
              <div style="display: flex;">
                <v-spacer />
                <v-btn :disabled="!isValidForm" :is-loading="isLoading" variant="flat" color="#00AEEF" @click="sendEmail">
                  Send
                </v-btn>
              </div>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    </v-card>
  </v-container>
</template>

<style scoped>
.contact-page {
  padding: 4rem;
  max-width: 1024px;
}

@media only screen and (max-width: 800px) {
  .contact-page {
    padding: 1rem;
  }
}
</style>