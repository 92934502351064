<template>
    <div class="fourohfour">
        <h1>404 Page Not Found :/</h1>
    </div>
</template>

<style scoped>
.fourohfour {
    text-align: center;
    margin: 5rem;
}
</style>