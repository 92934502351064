<script setup>
import { computed, onMounted } from 'vue';

const props = defineProps({
    href: {
        type: String,
        default: undefined,
    },
    type: {
        type: String,
        default: 'submit',
        validator(value) {
            return [
                'button',
                'submit',
            ].includes(value);
        },
    },
    // DEPRECATED: use slot instead
    msg: {
        type: String,
        default: '',
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    color: {
        type: String,
        default: '#00AEEF' 
    },
});

const emit = defineEmits(['click']);

onMounted(() => {
    if (props.msg) {
        console.warn('RelayButton: msg is deprecated, use slot instead');
    }
});

const element = computed(() => {
    return props.href ? 'a' : 'button';
});

const isDisabled = computed(() => {
    return props.isLoading || props.disabled;
})

const onClick = (event) => {
    emit('click', event);
}
</script>

<template>
    <component 
        :class="[
            'relay-button', 
            { 
                'relay-button--hover': !isDisabled, 
                'relay-button--disabled': disabled,
            }
        ]" 
        :is="element" 
        :href="isDisabled ? undefined : href" 
        :type="type"
        :disabled="isDisabled"
        @click="onClick"
    >
        <v-progress-circular 
            v-if="isLoading" 
            class="relay-button__spinner" 
            color="white"
            indeterminate
        />
        <span class="relay-button__text">
            <slot>
                {{ msg }}
            </slot>
        </span>
    </component>
</template>

<style scoped>
.relay-button {
    background-color: v-bind(color);
    border: none;
    padding: .75rem 1.5rem;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 32px;
    cursor: v-bind("isDisabled ? 'default' : 'pointer'");
    position: relative;
}

.relay-button--hover:hover {
    background-color: var(--color-text);
    color: white;
}

.relay-button--disabled {
    opacity: .3;
}

.relay-button__text {
    text-decoration: none;
    color: white;
    font-family:
        Inter,
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    font-size: 1rem;
    font-weight: bold;
    visibility: v-bind("isLoading ? 'hidden' : 'visible'");
}

.relay-button__spinner {
    position: absolute;
}
</style>