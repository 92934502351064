<script setup>
import { computed } from 'vue';

const props = defineProps({
  header: {
    type: String,
    required: true,
  },
  cost: {
    type: String,
    required: true,
  },
  href: {
    type: String,
    required: true,
  },
  buttonText: {
    type: String,
    required: true,
  },
  buttonStyle: {
    type: String,
    default: 'primary',
    validator(value) {
      return [
        'primary',
        'secondary',
      ].includes(value);
    },
  },
  comingsoon: {
    type: Boolean,
    default: false,
  }
});

const buttonStyles = computed(() => {
  if (props.buttonStyle === 'secondary') {
    return {
      'color': 'white',
      'textColor': '#161616',
    };
  }

  return {};
})
</script>

<template>
  <v-card class="pricing-card">
    <h3 class="pricing-card__header">{{ header }}</h3>
    <div :class="comingsoon && 'pricing-card__cost-coming-soon'">
      <!-- <p v-if="comingsoon">{{ cost }}</p> -->
      <p  class="pricing-card__cost">{{ cost }}</p>
      <slot name="subtext" class="pricing-card__subtext"></slot>
    </div>
    <v-btn @click="$router.push(href)" variant="flat" color="#00AEEF" v-bind="buttonStyles">{{ buttonText }}</v-btn>
    <div class="pricing-card__details">
      <slot name="details"></slot>
    </div>
  </v-card>
</template>

<style scoped>
.pricing-card {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  align-items: center;
  height: 100%;
  border-radius: 15px;
  gap: 50px;
}

.pricing-card__cost {
  font-size: 46px;
  line-height: 1;
  padding: .5rem 0;
  text-align: center;
}

.pricing-card__cost-coming-soon {
  font-size: 36px;
  line-height: normal;
  background-color: #00AEEF;
  color: white;
  width: 100%;
}

.pricing-card__cost-coming-soon .pricing-card__cost {
  font-size: 24px;
}

.pricing-card__header {
  font-size: 32px;
}

.pricing-card__details {
  font-size: 14px;
  padding: 0 2rem;
}

@media only screen and (max-width: 800px) {
  .pricing-card {
    padding: 2rem 0;
    gap: 12px;
  }

  .pricing-card__cost {
    font-size: 36px;
    line-height: normal;
  }

  .pricing-card__cost-coming-soon .pricing-card__cost {
    font-size: 18px;
  }
}
</style>