<script setup>
import RelayButton from './RelayButton.vue';
import { ref } from 'vue';
import { userStore } from '@/scripts/store';
import axios from 'axios';

const store = userStore();
const dialog = ref(false);

async function onSubmit() {
    await axios.post('/payments/cancel-subscription')
        .finally(() => {

            alert('Subscription cancelled.');
        });
    store.setSubscriptionStatus();
}
</script>

<template>
<v-dialog v-model="dialog" width="512">
    <template v-slot:activator="{ props }">
        <RelayButton class="button" v-bind="props">Cancel</RelayButton>
    </template>
    <v-card>
        <v-card-title>
        <span class="text-h5">Cancel Subscription</span>
        </v-card-title>
        <v-card-text>
        <v-container>
            By canceling your subscription you will lose access to all Relay services at the end of this billing cycle. Are you sure you wish to proceed?
        </v-container>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue-darken-1"
            @click="dialog=false">Cancel</v-btn>
        <RelayButton @click="dialog=false; onSubmit()" msg="Confirm" />
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<style scoped>
    .button {
        width: fit-content;
    }
</style>