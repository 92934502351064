<script setup>
import {ref, reactive, onMounted } from 'vue';
import axios from 'axios';

const headers = [
    { title: 'Email', key: 'email', align: 'start' },
    { title: 'Company Name', key: 'companyName', align: 'start'},
    { title: 'Joined', key: 'createdDate', align: 'start'},
    { title: 'Subscription', key: 'subscriptionType', align: 'start'},
    { title: 'Affiliates', key: 'totalAffiliates', align: 'start'},
    { title: 'Referrals', key: 'totalReferrals', align: 'start'},
    { title: 'Average Reward', key: 'averageRewardSize', align: 'start'}
]

const users = ref();
const itemsPerPage = ref(10);
const totalItems = ref(0);
const loading = ref(true);
const query = ref('');

const statsData = reactive({
    totalContractors: 0,
    totalRewards: 0,
    totalAffiliates: 0,
    totalReferrals: 0,
    averageRewardValue: 0,
    averageRewardsSentPerMonth: 0,
    averageReferralsPerAffiliate: 0,
    averageReferralsPerActiveAffiliate: 0
});

async function loadUsers({ page, itemsPerPage, sortBy }) {
    loading.value = true;
    await axios.get('/admin/dashboard-users', {
        params: {
            page,
            itemsPerPage,
            sortBy,
            query: query.value ?? null,
        }
    })
    .then((response) => {
        users.value = response.data.users;
        totalItems.value = response.data.total;
    })
    .finally(() => {
        loading.value = false;
    });
}

async function loadStats(){
    const { data } = await axios.get('/admin/dashboard-stats');
    console.log(data);
    statsData.totalContractors = data.totalContractors;
    statsData.totalRewards = data.totalRewards;
    statsData.totalAffiliates = data.totalAffiliates;
    statsData.totalReferrals = data.totalReferrals;
    statsData.averageRewardValue = data.averageRewardValue;
    statsData.averageRewardsSentPerMonth = data.averageRewardsSentPerMonth;
    statsData.averageReferralsPerAffiliate = data.averageReferralsPerAffiliate;
    statsData.averageReferralsPerActiveAffiliate = data.averageReferralsPerActiveAffiliate;
    console.log(statsData);
}

onMounted(() => {
    loadStats();
});

</script>

<template>
<v-container>
    <h4>Admin Stats</h4>
      <v-row>
        <v-col>
          <v-card height="200" :class="['d-flex justify-center align-center elevation-4 stat']">
            <v-col>
              <v-card-title class="title">{{ statsData.totalContractors }}</v-card-title>
              <v-card-subtitle class="subtitle">Total Contractors</v-card-subtitle>
            </v-col>
          </v-card>
        </v-col>
        
        <v-col>
            <v-card height="200" :class="['d-flex justify-center align-center elevation-4 stat']">
                <v-col>
                    <v-card-title class="title">{{ statsData.totalAffiliates }}</v-card-title>
                    <v-card-subtitle class="subtitle">Total Affiliates</v-card-subtitle>
                </v-col>
            </v-card>
        </v-col>
        
        <v-col>
            <v-card height="200" :class="['d-flex justify-center align-center elevation-4 stat']">
                <v-col>
                    <v-card-title class="title">{{ statsData.totalReferrals }}</v-card-title>
                    <v-card-subtitle class="subtitle">Total Referrals</v-card-subtitle>
                </v-col>
            </v-card>
        </v-col>

        <v-col>
          <v-card height="200" :class="['d-flex justify-center align-center elevation-4 stat']">
            <v-col>
              <v-card-title class="title">{{ statsData.totalRewards }}</v-card-title>
              <v-card-subtitle class="subtitle">Total Rewards Sent</v-card-subtitle>
            </v-col>
          </v-card>
        </v-col>
    </v-row>
      
      <v-row>
        <v-col>
          <v-card height="200" :class="['d-flex justify-center align-center elevation-4 stat']">
            <v-col>
              <v-card-title class="title">{{ statsData.averageRewardValue }}</v-card-title>
              <v-card-subtitle class="subtitle">Average Reward Value</v-card-subtitle>
            </v-col>
          </v-card>
        </v-col>

        <v-col>
          <v-card height="200" :class="['d-flex justify-center align-center elevation-4 stat']">
            <v-col>
              <v-card-title class="title">{{ statsData.averageRewardsSentPerMonth }}</v-card-title>
              <v-card-subtitle class="subtitle">Average Rewards Sent per Month</v-card-subtitle>
            </v-col>
          </v-card>
        </v-col>
        
        <v-col>
          <v-card height="200" :class="['d-flex justify-center align-center elevation-4 stat']">
            <v-col>
              <v-card-title class="title">{{ statsData.averageReferralsPerAffiliate }}</v-card-title>
              <v-card-subtitle class="subtitle">Average Referrals per Affiliate</v-card-subtitle>
            </v-col>
          </v-card>
        </v-col>

        <v-col>
          <v-card height="200" :class="['d-flex justify-center align-center elevation-4 stat']">
            <v-col>
              <v-card-title class="title">{{ statsData.averageReferralsPerActiveAffiliate }}</v-card-title>
              <v-card-subtitle class="subtitle">Average Referrals per Active Affiliate</v-card-subtitle>
            </v-col>
          </v-card>
        </v-col>
    </v-row>

    <v-row>
        <div class="d-flex padded">
            <h2>Users</h2>
            <v-spacer />
            <v-text-field
                v-model="query"
                label="Search"
                prepend-inner-icon="mdi-magnify"
                hide-details
                single-line
                class="search-bar"
                cols="6"
            ></v-text-field>
        </div>
        <v-data-table-server
            class="table"
            v-model:items-per-page="itemsPerPage"
            :headers="headers"
            :items-length="totalItems"
            :items="users"
            :loading="loading"
            :search="query"
            item-value="email"
            fixed
            @update:options="loadUsers"
        >
            <template v-slot:item="{ item }">
                <tr>
                    <td>{{ item.email }}</td>
                    <td>{{ item.companyName }}</td>
                    <td>{{ item.createdDate }}</td>
                    <td>{{ item.subscriptionType }}</td>
                    <td>{{ item.totalAffiliates }}</td>
                    <td>{{ item.totalReferrals }}</td>
                    <td>{{ item.averageRewardSize }}</td>
                </tr>
            </template>
        </v-data-table-server>
    </v-row>
</v-container>
<br><br><br>
</template>

<style scoped>
.d-flex {
    display: flex;
    align-items: center; /* Align items vertically center */
    margin-bottom: 16px; /* Add some space below */
}

.padded {
    padding: 8px; /* Add some padding */
}

.search-bar {
    max-width: 400px; /* Adjust the max-width as needed */
    min-width: 175px;
    padding-left: 10%;
}
</style>