<script setup>
import RelayButton from './RelayButton.vue';
import { ref, reactive } from 'vue';

const props = defineProps({
    affiliate: Object,
})

const dialog = ref(false);
const affiliate = reactive(props.affiliate);

</script>
<template>
    <v-dialog v-model="dialog" width="512">
        <template v-slot:activator="{ props }">
            <a v-bind="props" class="view-more-menu-item">View Details</a>
        </template>
        <v-card>
            <v-card-title>
            <span class="text-h5">{{ affiliate.firstName }} {{ affiliate.lastName }}</span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-list>
                    <v-list-item-title>Email</v-list-item-title>
                    <v-list-item>{{ affiliate.email }}</v-list-item>

                    <v-list-item-title>Phone</v-list-item-title>
                    <v-list-item>{{ affiliate.phone }}</v-list-item>

                    <v-list-item-title>Affiliate Since:</v-list-item-title>
                    <v-list-item>{{ affiliate.createdDate }}</v-list-item>

                    <v-list-item-title>Reward</v-list-item-title>
                    <v-list-item>{{ affiliate.reward }}</v-list-item>

                    <v-list-item-title>Total Referrals</v-list-item-title>
                    <v-list-item>{{ affiliate.numberOfReferrals }}</v-list-item>

                    <v-list-item-title>Total Referrals Completed</v-list-item-title>
                    <v-list-item>{{ affiliate.numberOfCompletedReferrals }}</v-list-item>

                    <v-list-item-title>Total Link Clicks</v-list-item-title>
                    <v-list-item>{{ affiliate.numberOfClicks }}</v-list-item>

                    <v-list-item-title>Affiliate Link</v-list-item-title>
                    <v-list-item>{{ affiliate.affiliateContractorLink }}</v-list-item>

                </v-list>
            </v-container>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue-darken-1"
                @click="dialog=false">Back</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style>
    .view-more-menu-item {
        cursor: pointer;
    }
</style>