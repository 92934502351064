<script setup>
</script>

<template>
    <v-container class="faq-panels">
        <div>
            <h3>Introduction Video</h3>
            <div style="position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; height: auto;">
                <iframe 
                    class="relay-video"
                    src="https://www.youtube.com/embed/q1olTy_S9hQ?si=DGpPigNX45NAqPl_"
                    title="YouTube video player"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                </iframe>
            </div>
        </div>

        <br>
        <h3>Frequently Asked Questions</h3>
        <v-expansion-panels class="panels">
            <v-expansion-panel>
              <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
                How do I create an affiliate?
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <p class="panel-text">
                    You can access the affiliates page by clicking “view more” next to affiliates on the dashboard or you can click on affiliates in the sidebar. If you're on a phone, click on the hamburger menu and the same side bar navigation will appear. 
                </p>
                <p>
                    You have two options to create an affiliate. Bulk upload or one at a time. To create a single affiliate, click on the “create affiliate” button. Enter in their first name, last name, email address, and phone number. Then click submit. 
                    Lastly, your referrals will automatically be converted into affiliates after their job has been completed.
                </p>
              </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="panels">
            <v-expansion-panel>
                <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
                    How do I access my dashboard?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    From the Relay homepage login if you haven't already. 
                    Access to your dashboard will appear next to your name in the top right of the screen. Simply click on Dashboard to get started.
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="panels">
            <v-expansion-panel>
                <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
                    How do I send rewards?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                        <p class="panel-text">
                            Once the work has been completed, you will reward your affiliate by clicking “mark complete” next to your referral's name. 
                            Confirm the payment and reward amount in the pop-up and click submit. 
                        </p>
                        <p>
                            This will trigger two additional communications. 
                            First, your affiliate will receive an email and SMS inviting them to redeem their reward. 
                            All they need to do is click on “choose a gift card” and they can redeem their reward from a selection of over 1,000 top-brand gift cards, including Amazon and Visa. 
                            Second, the referred customer will receive an appreciation email and/or SMS, inviting them to become an affiliate and share your business. 
                        </p>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="panels">
            <v-expansion-panel>
                <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
                    Can I add a referral that came through without an affiliate link?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                        <p class="panel-text">
                            Another scenario you may run into is receiving a referral that doesn't come through an affiliate link. 
                            If that happens, you can always enter a referral into the system manually.
                        </p>
                        <p>
                            All you need to do is click on “create new referral”, enter in the referral's contact information, and add the affiliate responsible for the referral.
                        </p>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="panels">
            <v-expansion-panel>
                <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
                    Can I send a bulk list all at once?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                        <p class="panel-text">
                            A bulk upload allows you to create up to 1,000 affiliates at a time. 
                            Each affiliate will receive their own unique link via email and/or SMS.
                        </p>
                        <p>
                            Enter your contact's information in an excel spreadsheet using the example image that appears when you click on “Bulk Upload” to format your list correctly. 
                            From there, simply upload the file.
                        </p>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="panels">
            <v-expansion-panel>
                <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
                    How do email and text notifications work?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                        <p>
                            When creating an affiliate, you can insert the affiliate's email address, phone number, or both. 
                            Ideally, you have both the affiliate's email address and phone number to ensure they receive communications through both channels. 
                            However, if you only have one, they will still receive all communications through that medium.
                        </p>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="panels">
            <v-expansion-panel>
                <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
                    Why do I have unwanted spaces in my custom emails and SMS communications? 
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                        <p>
                            Make sure you eliminate any spaces at the end of all text inserted in each company information field. 
                            A space will go unnoticed in your back-office but will create a gap in the email's subject line and body.
                        </p>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="panels">
            <v-expansion-panel>
                <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
                    Why can't I create an affiliate without credit card and company information?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                        <p class="panel-text">
                            After you sign in, you will see a pop-up, letting you know that before you can create an affiliate, you need to fill out your company information and insert a payment method. 
                            Rest assured, your first 30 days are free, but you need a payment method on file in order to send rewards to your affiliates. 
                        </p>
                        <p>
                            In addition, Relay cannot send your affiliates customized emails and SMS communications without the requested company information. 
                        </p>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="panels">
            <v-expansion-panel>
                <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
                    How fast are email and text notifications sent?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                        <p>
                            All emails and SMS notifications should be sent and received within a few seconds, however short delays may occur.
                        </p>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="panels">
            <v-expansion-panel>
                <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
                    How do I modify my reward and how does that affect my affiliate?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                        <p class="panel-text">
                            Adjusting the affiliate reward amount is simple. You can adjust the reward amount in your back office under the “My Affiliates” section.
                        </p>
                        <p class="panel-text">
                            Changes made to the affiliate reward will not change the affiliate's link, simply the reward associated with that link. 
                            In an effort to always be transparent, Relay will communicate with your affiliate connected to that link that the reward has been updated.
                        </p>
                        <p>
                            Please note that any referrals that are already recorded in the system, cannot have their referral reward changed. 
                        </p>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="panels">
            <v-expansion-panel>
                <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
                    How do affiliate rewards work?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                        <p class="panel-text">
                            Each time an affiliate shares their referral link, you'll be notified when a new potential customer clicks and provides their information. 
                            After you contact the referral and complete the job, you simply go to your back office and click “mark complete” next to the referrals name. 
                            This triggers our system to send the preset reward amount to the affiliate that referred them. 
                        </p>
                        <p>
                            No chasing down information, no venmo transactions, just pure customer satisfaction. 
                            And the best part, the referral automatically becomes your newest affiliate to help you find new customers. 
                            Your new affiliate will receive an appreciation email and invitation to share their custom referral. 
                        </p>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="panels">
            <v-expansion-panel>
                <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
                    What are the rewards?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                        <p>
                            Relay supports all major gift cards as well as Visa cash. 
                            We've done extensive market research to make sure that we have the hottest and most popular gift cards available. 
                            Your customers will LOVE being able to choose from more than 1,000 different brands. 
                        </p>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="panels">
            <v-expansion-panel>
                <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
                    How do I set rewards?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                        <p>
                            Set your default reward in your company information page or set a custom reward amount when creating an individual affiliate. 
                            The reward amount will be included in all custom affiliate communications and the reward will be sent once the job is completed and the referral successful.
                        </p>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="panels">
            <v-expansion-panel>
                <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
                    How does billing work?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                        <p>
                            Relay will charge your account automatically each time a referral reward is sent to an affiliate. 
                            You will also be charged a monthly membership fee that will be billed on the day of the month you joined Relay. 
                            We accept ACH transfer or any major credit or debit card as payment methods.  
                        </p>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>

<style scoped>
.faq-panels{
    padding-top: 1dvh;
    width: 70%;
    margin-bottom: 5%;
    margin-left: 5%;
}
.panels {
  /* Ensure panels are left-aligned */
  padding-top: 1%;
  text-align: left;
}
.panel-text{
    margin-bottom: 20px;
}
</style>
