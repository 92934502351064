<script setup>
import axios from 'axios';
import { useRouter } from 'vue-router';
import { ref } from 'vue';

import { userStore } from '@/scripts/store';
import SplitView from '@/components/SplitView.vue';
import RelayButton from '../components/RelayButton.vue';
import { rules } from '@/utils/validation.js';
import RelayExternalAuth from '@/components/RelayExternalAuth.vue';

const store = userStore();
const router = useRouter();

const isLoading = ref(false);

const email = ref('');
const password = ref('');
const rememberMe = ref(false);
const hasError = ref(false);

const onSubmit = async () => {
    isLoading.value = true;
    hasError.value = false;

    try {
        const response = await axios.post('/login-relay', {
            email: email.value,
            password: password.value,
            // rememberMe: rememberMe.value,
        }, 
        // {
        //     params: {
        //         useCookies: true,
        //         useSessionCookies: rememberMe.value,
        //     }
        // }
        );
        
        if (response.status !== 200) {
            throw new Error('login not successful!')
        }
        await store.login();
        await store.checkPaymentMethod();
        
        router.push('/dashboard');
    } catch (e) {
        console.log(e);
        //TODO: REMOVE THIS
        hasError.value = true;
    } finally {
        isLoading.value = false;
    }
}

const valid = ref(false);
</script>

<template>
    <SplitView>
    <div class="login">
        <h3>Login</h3>
        <relay-external-auth :register="false"/>
        <div class="line"><span>OR</span></div>
        <v-form class="form" v-model="valid">
            <v-text-field 
                v-model="email"
                name="email" 
                placeholder="Email" 
                type="email" 
                class="form-control" 
                :rules="[rules.required]"
                aria-label="email"
                required
            />
            <v-text-field 
                v-model="password"
                name="password" 
                placeholder="Password" 
                type="password" 
                class="form-control" 
                :rules="[rules.required]"
                aria-label="password"
                required
            />
            <v-alert
                v-if="hasError"
                text="Incorrect username or password"
                color="error"
                icon="$error"
                variant="text"
            />
            <v-checkbox label="Remember Me" v-model="rememberMe" />
            <relay-button 
                @click.prevent="onSubmit" 
                :disabled="!valid"
                :is-loading="isLoading"
            >
                Login
            </relay-button>
        </v-form>
        <div class="row">
            <a href="/ForgotPassword">Forgot password</a>
            <a href="/join">Sign up</a>
        </div>
    </div>
</SplitView>
</template>

<!-- todo:
- how to render a different template
- add axios
- add favicon back
- track auth across the page
-->

<style scoped>
.form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.login {
    width: 28rem;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background-color: white;
    gap: 1rem;
    padding: 2rem;
}

.row {
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    margin: 1rem 1rem 0 1rem;
}

.line {
   width: 100%; 
   text-align: center; 
   border-bottom: 1px solid #000; 
   line-height: 0.1em;
   margin: 10px 0 20px; 
} 

.line span { 
    background:#fff; 
    padding:0 10px; 
}
</style>