<template>
  <v-container class="why-relay-container">
    <v-row class="why-relay-container__row">
      <h1 class="why-relay-container__row__title">Get More Referrals, Faster</h1>
      <br>
      <h3>Relay is your portal to accessing the power of digital marketing without wasting money on low-yield ads. Tap
        into the power of your most effective business building strategy!</h3>
    </v-row>
  </v-container>

  <div class="break"></div>

  <v-container class="why-relay-testimonial">
    <v-card class="why-relay-testimonial__card" max-width="1024">
      <v-card-title>
        <span class="material-symbols-outlined card-icon">star</span>
        <span class="material-symbols-outlined card-icon">star</span>
        <span class="material-symbols-outlined card-icon">star</span>
        <span class="material-symbols-outlined card-icon">star</span>
        <span class="material-symbols-outlined card-icon">star</span>
        <h1>Carla, Photographer</h1>
      </v-card-title>
      <v-card-text>
        <br>
        <blockquote>
          <p>"Relay has been a game-changer for my photography business! My affiliate program not only expanded my
            client base but also provided a steady stream of income. It's like having a dedicated sales team
            working for me 24/7. Thank you, Relay!"</p>
        </blockquote>
      </v-card-text>
    </v-card>
  </v-container>

  <br>

  <div class="card-list-title">
    <h1>Why should you choose affiliate marketing?</h1>
  </div>
  <v-container style="display: flex; flex-direction: column; align-items: center;">
    <v-row>
      <v-col>
        <v-card flat class="card">
          <v-card-title>
            <h3>We Do What Works!</h3>
          </v-card-title>
          <v-card-text>
            <p>The Relay platform is your simple solution to creating brand affiliates and is a game-changer for
              independent service providers looking to supercharge their “referral” customer acquisition strategy.</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card flat class="card">
          <v-card-title>
            <h3>We Improve Engagement. </h3>
          </v-card-title>
          <v-card-text>
            <p>We transform satisfied customers into engaged affiliates by rewarding them for the successful referrals
              they send your way.</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card flat class="card">
          <v-card-title>
            <h3>Streamline Your Success</h3>
          </v-card-title>
          <v-card-text>
            <p>Our platform takes care of the entire process, affording you the time to do what you do best.</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <br>

  <v-container class="bg-relay-blue" :style="'padding:4rem'">
    <v-row>
      <v-col>
        <h2 style="font-size: 40px;">Referrals On Repeat</h2>
      </v-col>
      <v-col>
        <p>Relay is built on the idea that success is just one referral away. With our simple to use dashboard, you can
          manage new potential customers, referrals, and rewards from one central location.</p>
      </v-col>
      <div class="line"></div>
    </v-row>

    <v-row>
      <v-col>
        <h2 style="font-size: 40px;">Email Rewards</h2>
      </v-col>
      <v-col>
        <p>Relay does the heavy lifting for you. Once a job is complete, your custom rewards are seamlessly sent
          without you needing to communicate with your affiliates.</p>
      </v-col>
      <div class="line"></div>
    </v-row>

    <v-row>
      <v-col>
        <h2 style="font-size: 40px;">Generate Referral Links</h2>
      </v-col>
      <v-col>
        <p>With Relay, generating referral links is as easy as knowing a name and phone number or email address. Our
          dashboard keeps the process simple and effective.</p>
      </v-col>
    </v-row>
  </v-container>

  <br>

  <v-container class="why-relay-container">
    <v-row class="why-relay-container__row">
      <h1>Ditch the ads. Automate your success.</h1>
    </v-row>
  </v-container>
</template>

<style scoped>
.why-relay-container {
  background-color: #00AEEF;
}

.why-relay-container__row {
  color: white;
  padding: 8rem;
  text-align: center;
}

.why-relay-container__row__title {
  font-size: 46px;
  width: 100%;
}

.why-relay-testimonial {
  display: flex;
  justify-content: center;
}

.why-relay-testimonial__card {
  padding: 2rem;
  margin-top: -3rem;
}

.why-relay-testimonial__card h1 {
  margin-top: 1rem;
}

.why-relay-testimonial blockquote {
  border-left: 2px solid black;
  padding-left: 1rem;
  font-size: 20px;
  line-height: 30px;
}

.card-list-title {
  width: 100%;
  text-align: center;
}

.card-icon {
  color: gold;
  font-size: 40px;
}

.card {
  border: 3px solid #00AEEF;
  padding: 1rem;
  height: 100%;
}

.bg-relay-blue {
  background-color: #262262;
  color: white;
}

.line {
  width: 100%;
  border-bottom: 2px white solid;
}

@media only screen and (max-width: 800px) {
  .why-relay-container__row {
    padding: 2rem;
    padding-bottom: 3rem;
    text-align: left;
  }

  .why-relay-container__row__title {
    font-size: 32px;
    padding-bottom: 1rem;
  }

  .why-relay-container__row h3 {
    font-size: 18px;
  }

  .why-relay-testimonial__card {
    padding: 1rem;
  }

  .why-relay-testimonial__card h1 {
    font-size: 26px;
  }

  .why-relay-testimonial__card p {
    font-size: 16px;
  }
  
  .card-list-title h1 {
    font-size: 28px;
    padding: 0 2rem;
  }
}
</style>