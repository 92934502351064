<script setup>
import RelayRouterButton from './RelayRouterButton.vue';

const props = defineProps({
    img: String,
    flip: Boolean,
})

</script>

<template>
    <div class="container">
        <v-img cover :src="props.img" eager :class="[flip && 'flipped']"/>
        <v-card class="content rounded-0" flat>
            <div class="inner"> 
                <slot></slot>
            </div>
        </v-card>
    </div>
</template>

<style scoped>
.container {
    width: 100%;
    max-height: 600px;
    position: relative;
    overflow: hidden;
}

.flipped {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.content {
    color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #00000066;
}

.inner {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

h1 {
    font-size: 64px;
    font-weight: 700;
    line-height: 70px;
    padding: 64px;
}

:slotted(.slot-text) {
    font-size: 60px;
    align-self: end;
    margin-left: 4rem;
    margin-bottom: 2rem;
}

@media only screen and (max-width: 700px) {
    .content {
        width: unset;
        left: 0;
        top: 0;
        position: relative;
    }

    .inner {
        width: 100%;
    }

    :slotted(.slot-text) {
        font-size: 32px;
        align-self: unset;
        width: 100%;
        margin: auto;
        padding: 1rem;
    }
}
</style>