<script setup>
import { userStore } from '../scripts/store';

const store = userStore();
</script>

<template>
  <v-app-bar class="nav" flat density="prominent" color="#262262">
    <router-link to="/">
      <img alt="Relay Logo" class="logo" src="../assets/images/dashboard-logo.png" width="180px" />
    </router-link>
    <v-spacer />
    <div class="nav">
      <span class="material-symbols-outlined">account_circle</span>
      <p class="user">{{ store.user }}</p>
    </div>
  </v-app-bar>
</template>

<style scoped>
:deep(.v-toolbar--density-prominent),
:deep(.v-toolbar__content) {
  align-items: center !important;
}

.nav {
  display: flex;
  align-items: center;
}

.user {
  padding: 6px;
  padding-right: 4rem;
  font-weight: bold;
}

@media only screen and (max-width: 1024px) {
  .user {
    width: auto;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    padding-right: 1rem;
    text-align: right;
  }
}
</style>
