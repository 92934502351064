<script setup>
import DashboardNavigation from '@/components/DashboardNavigation.vue';
import { ref } from 'vue';
import { userStore } from '../scripts/store';
import { useDisplay } from 'vuetify';

const store = userStore();
const drawer = ref(null);

const { mobile } = useDisplay();
</script>

<template>
  <dashboard-navigation />
  <v-container style="display: flex; align-items: center; padding-bottom: 0">
    <v-btn v-if="mobile" @click.stop="drawer=!drawer" :ripple="false" variant="plain" size="x-small">
      <span class="material-symbols-outlined">menu</span>
    </v-btn>
    <h2>{{ $route.meta.title }}</h2>
  </v-container>
  <v-navigation-drawer class="bg-gray" :location="mobile ? 'bottom' : 'left'" v-model="drawer" floating fixed mobile style="height: auto;">
    <v-list lines="two" :density="mobile ? 'compact' : 'default'">
      <v-list-item link @click="$router.push('/dashboard')" title="Dashboard">
        <template v-slot:prepend>
          <span class="material-symbols-outlined mx-2">grid_view</span>
        </template>
      </v-list-item>
      <v-list-item link @click="$router.push('/dashboard/referrals')" title="Referrals">
        <template v-slot:prepend>
          <span class="material-symbols-outlined mx-2">email</span>
        </template>
      </v-list-item>
      <v-list-item link @click="$router.push('/dashboard/affiliates')" title="Affiliates">
        <template v-slot:prepend>
          <span class="material-symbols-outlined mx-2">groups</span>
        </template>
      </v-list-item>
      <v-list-item link @click="$router.push('/dashboard/company')">
        <template v-slot:prepend>
          <span class="material-symbols-outlined mx-2">corporate_fare</span>
        </template>
        <template v-slot:title>
          <span class="wrap-text">Company Settings</span>
        </template>
      </v-list-item>
      <v-list-item link @click="$router.push('/dashboard/settings')">
        <template v-slot:prepend>
          <span class="material-symbols-outlined mx-2">settings</span>
        </template>
        <template v-slot:title>
          <span class="wrap-text">Account Settings</span>
        </template>
      </v-list-item>
      <v-list-item link @click="$router.push('/dashboard/help')" title="Help">
        <template v-slot:prepend>
          <span class="material-symbols-outlined mx-2">help</span>
        </template>
      </v-list-item>
      <v-list-item v-if="store.userIsAdmin" link @click="$router.push('/dashboard/admin')" title="Admin">
        <template v-slot:prepend>
          <span class="material-symbols-outlined mx-2">key</span>
        </template>
      </v-list-item>
      <v-list-item link @click="store.logout()" title="Logout">
        <template v-slot:prepend>
          <span class="material-symbols-outlined mx-2">logout</span>
        </template>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
  <router-view />
</template>

<style scoped>
:deep(.v-navigation-drawer__content) {
  margin: 2rem;
  background-color: #00AEEF;
  color: white;
  border-radius: 5px;
}

:deep(.v-list-item-title) {
  font-weight: bold;
}

.bg-gray {
  background-color: #f4f4f4;
}

.nav-button {
  background-color: #00AEEF;
  color: white;
  width: 100%;
}

.activator {
  display: none;
}

.wrap-text {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-weight: bold;
}

@media only screen and (max-width: 960px) {
  :deep(.v-navigation-drawer__content) {
    margin: 0;
    background-color: #00AEEF;
    color: white;
    border-radius: 0px;
  }

  .activator {
    display: block;
    z-index: 100;
    position: fixed;
    bottom: 1rem;
    left: 2rem;
  }
}
</style>