<template>
    <div @click="$router.back()">
        <span class="material-symbols-outlined">keyboard_backspace</span>
    </div>
</template>

<style scoped>
    div {
        display: flex;
        margin: 1rem;
        position: absolute;
    }

    div span, div h4 {
        cursor: pointer;
    }

    div {
    background-color: var(--color-background-soft);
    border: none;
    padding: .75rem 1.5rem;
    /* text-decoration: none; */
    color: var(--color-text);
    cursor: pointer;
    text-align: center;
    border-radius: 32px;
    font-family:
        Inter,
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    }

div:hover, div:hover {
  background-color: var(--color-text);
  color: var(--color-background-soft);
}
</style>