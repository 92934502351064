<template>
<v-btn class="contact-button" @click="$router.push('/contact')" variant="flat" color="#00AEEF">
    <span class="material-symbols-outlined">chat</span>
</v-btn>
</template>

<style scoped>
.contact-button {
    z-index: 100;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
}
</style>