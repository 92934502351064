<script setup>
import axios from 'axios';
import { reactive, onMounted } from 'vue';
import { userStore } from '@/scripts/store';
import { StripePublicKey } from '../utils/publicKeys';
import SubscriptionUpgrade from '../components/SubscriptionUpgrade.vue';
import SubscriptionCancel from '../components/SubscriptionCancel.vue';
import RelayDeleteAccount from '@/components/RelayDeleteAccount.vue';

const store = userStore();
const stripePublicKey = StripePublicKey();
const stripe = Stripe(stripePublicKey);
const cardCircles = '&#x25CF;';
const noRelaySubscriptionId = 1;

const paymentMethod = reactive({
  cardBrand: "Card Brand",
  cardLastFour: "----",
  hasCard: false,
  cardDisplay: "",
})

const subscription = reactive({
  id: 0,
  name: " ",
  price: 0,
  nextBillingDate: " ",
  cancelled: false,
});

onMounted(async () => {
  loadData();
})

async function loadData() {
  try {
    const paymentMethodData = (await axios.get('/payments/get-payment-method')).data;
    const subscriptionData = (await axios.get('/payments/get-subscription-details')).data;

    paymentMethod.cardBrand = paymentMethodData.cardBrand;
    paymentMethod.cardLastFour = paymentMethodData.cardLastFour;
    paymentMethod.cardBrand = paymentMethod.cardBrand;
    if (paymentMethod.cardBrand != null) {
      paymentMethod.cardBrand = paymentMethod.cardBrand.charAt(0).toUpperCase() + paymentMethod.cardBrand.slice(1);
    }
    if (paymentMethod.cardBrand != null && paymentMethod.cardLastFour != null) paymentMethod.hasCard = true;
    paymentMethod.cardDisplay = `${paymentMethod.cardBrand} ${cardCircles.repeat(4)} ${cardCircles.repeat(4)} ${cardCircles.repeat(4)} ${paymentMethod.cardLastFour}`;

    subscription.id = subscriptionData.id;
    subscription.name = subscriptionData.name;
    subscription.price = subscriptionData.price;
    subscription.nextBillingDate = subscriptionData.nextBillingDate;
    subscription.cancelled = subscriptionData.subscriptionCancelled;
  }
  catch (error) {
    console.log(error);
  }
}

async function updatePaymentMethod() {
  const fetchClientSecret = async () => {
    const response = await fetch("/stripe/create-payment-method", {
      method: "POST",
    });
    const { clientSecret } = await response.json();
    return clientSecret;
  };

  const checkout = await stripe.initEmbeddedCheckout({
    fetchClientSecret,
  });

  // Mount Checkout
  checkout.mount('#stripe-payment-method');
}

async function removePaymentMethod() {
  try {
    await axios.post('/payments/remove-payment-method');
    await store.checkPaymentMethod();
  }
  catch (error) {
    console.log(error);
  }
}
</script>

<template>
  <v-container style="background-color: #F4F4F4;">
  <v-card class="card" max-width="1024" flat>
    <v-card-title>Account Information</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          {{ subscription.name }}
        </v-row>
        <v-row v-if="subscription.id != noRelaySubscriptionId && subscription.cancelled === false">
          Next Payment: ${{ subscription.price }} on {{ subscription.nextBillingDate }}
        </v-row>
        <v-row v-else-if="subscription.id != noRelaySubscriptionId && subscription.cancelled">
          Subscription Expires: {{ subscription.nextBillingDate }}
        </v-row>
        <v-row v-if="subscription.id === noRelaySubscriptionId">
          <SubscriptionUpgrade :buttonText="'Upgrade'" @update="loadData()"/>
        </v-row>
        <v-row v-else-if="subscription.cancelled && subscription.id != noRelaySubscriptionId">
          <SubscriptionUpgrade :buttonText="'Resume'" @update="loadData()"/>
        </v-row>
        <v-row v-else-if="subscription.cancelled === false">
          <SubscriptionCancel @update="loadData()"/>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
  <v-card class="card" max-width="1024" flat>
    <v-card-title>Payment Info</v-card-title>
    <v-card-text>
      <v-container>
        <v-row v-if="paymentMethod.hasCard" v-html="paymentMethod.cardDisplay">
        </v-row>
        <v-row v-else>
          No Payment Method
        </v-row>
        <v-row>
          <v-btn @click="updatePaymentMethod()">Update Payment Method</v-btn>
        </v-row>
        <v-row v-if="paymentMethod.hasCard">
          <v-btn @click="removePaymentMethod()">Remove Payment Method</v-btn>
        </v-row>
      </v-container>
    </v-card-text>
    <!-- Stripe Checkout Element Goes Here -->
    <div id="stripe-payment-method">
    </div>
  </v-card>
  <v-card class="card" max-width="1024" flat>
    <v-card-title>Password</v-card-title>
    <v-card-text>
      <v-container>
        <v-text-field></v-text-field>
        <v-text-field></v-text-field>
        <v-btn>Change Password</v-btn>
      </v-container>
    </v-card-text>
  </v-card>
  <relay-delete-account />
</v-container>
</template>

<style scoped>
.card {
  margin-top: 1rem;
}
</style>