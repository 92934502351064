<script setup>
import RelayButton from './RelayButton.vue';
import { ref, reactive, defineEmits } from 'vue';
import axios from 'axios';

const props = defineProps({
    affiliate: Object,
})

const emit = defineEmits(['update']);

const editAffiliateDialog = ref(false);
const rewardAlert = ref(false);
const minValue = 5;
const maxValue = 2000;
const affiliate = reactive({
    id: props.affiliate.id,
    firstName: props.affiliate.firstName,
    lastName: props.affiliate.lastName,
    email: props.affiliate.email,
    phone: props.affiliate.phone,
    rewardValue: props.affiliate.rewardValue,
})

const alertMessage = ref("");
const errorAlert = ref(false);
const loading = ref(false);

function validateRewardValue(){
  if (affiliate.rewardValue > maxValue || affiliate.rewardValue < minValue){
    rewardAlert.value = true;
  }
}

async function onSubmit() {
    loading.value = true;
    try{
        validateRewardValue();
        await axios.post('/affiliate/edit', affiliate);
        loading.value = false;
        errorAlert.value = false;
        editAffiliateDialog.value = false;
        emit('update');
        alert('Affiliate Updated - Notification Sent');
    }catch(error){
        console.log(error);
        if (error.response && error.response.data && error.response.data.userErrorMessage){
            alertMessage.value = error.response.data.userErrorMessage;
            errorAlert.value = true;
            loading.value = false;
        }
        else{
            alertMessage.value = "Error processing affiliate update, please review and try again."
            errorAlert.value = true;
            loading.value = false;
        }
    }
}
</script>
<template>
    <v-dialog v-model="editAffiliateDialog" width="512">
        <template v-slot:activator="{ props }">
            <a v-bind="props" class="view-more-menu-item">Edit</a>
        </template>
        <v-card>
            <v-card-title>
            <span class="text-h5">Update Affiliate</span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-row>
                    <v-text-field label="First Name" persistent v-model="affiliate.firstName"></v-text-field>
                    <v-text-field label="Last Name" persistent v-model="affiliate.lastName"></v-text-field>
                </v-row>
                <v-row>
                    <v-text-field label="Email" persistent v-model="affiliate.email" type="tel"></v-text-field>
                </v-row>
                <v-row>
                    <v-text-field label="Phone" persistent v-model="affiliate.phone" type="tel"></v-text-field>
                </v-row>
                <v-row>
                    <v-text-field 
                        label="Reward Amount"
                        hint="Rewards must be between $5-$2000"
                        persistent 
                        v-model="affiliate.rewardValue" 
                        type="number"></v-text-field>
                </v-row>
            </v-container>
            </v-card-text>
            <v-alert
                v-model="rewardAlert"
                title="Invalid reward amount"
                type="error"
                >Amount must be between $5 and $2000</v-alert>
            <v-alert
                v-model="errorAlert"
                :text="alertMessage"
                title="Error updating affiliate"
                type="error"
                ></v-alert>
            <v-card-actions v-if="!loading">
                <v-spacer></v-spacer>
                <v-btn
                    color="blue-darken-1"
                    @click="editAffiliateDialog=false">Cancel</v-btn>
                <RelayButton @click="onSubmit()" msg="Submit" />
            </v-card-actions>
            <v-card-actions v-else>
                <v-spacer></v-spacer>
                <v-progress-circular
                    color="primary"
                    class="text-right"
                    indeterminate
                    ></v-progress-circular>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style>
    .view-more-menu-item {
        cursor: pointer;
    }
</style>