<template>
    <div>
        <div class="content">
            <div class="column">
                <img alt="Relay Logo" class="logo" src="../assets/images/logo.png" />
            </div>
        </div>
    </div>
</template>

<style scoped>
div {
    background-color: white;
    display: flex;
    justify-items: center;
}

.content {
    max-width: 1310px;
    padding: 2rem 4rem;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

img {
    width: 140px;
}

@media only screen and (max-width: 1150px) {
    .content {
        padding: 2rem 0;
    }

    img {
        width: 100px;
    }

    p {
        font-size: 12px;
    }
}
</style>