<script setup>
import axios from 'axios';
import { useRouter } from 'vue-router';
import { userStore } from '@/scripts/store';
import { ref } from 'vue';

import SplitView from '@/components/SplitView.vue';
import RelayButton from '../components/RelayButton.vue';
import { rules } from '@/utils/validation.js';

import RelayExternalAuth from '../components/RelayExternalAuth.vue';

const store = userStore();
const router = useRouter();

const email = ref('');
const password = ref('');
const valid = ref(false);
const isLoading = ref(false);

const onSubmit = async () => {
    errors.value = [];
    isLoading.value = true;

    try {
        await axios.post('/register-relay', {
            email: email.value,
            password: password.value,
        });

        await store.login();
        router.push('/dashboard?joined=true');
    } catch ({ response}) {
        if (response?.data?.errors) {
            Object.values(response.data.errors).forEach(errorList => {
                errorList.forEach(error => errors.value.push(error))
            });
        } else {
            errors.value[0] = 'Something went wrong';
        }
    } finally {
        isLoading.value = false;
    }
};

const errors = ref([]);
</script>


<template>
    <split-view>
        <div class="register">
            <h3>Join for free</h3>
            <relay-external-auth :register="true"/>
            <div class="line"><span>OR</span></div>
            <v-form class="form" v-model="valid">
                <v-text-field 
                    v-model="email"
                    name="email" 
                    placeholder="Email" 
                    type="email" 
                    :rules="[rules.required, rules.email]"
                />
                <v-text-field 
                    v-model="password"
                    name="password" 
                    placeholder="Password" 
                    type="password" 
                    :rules="[rules.required]"
                />
                <div v-if="errors.length">
                    <v-alert
                        v-for="error in errors"
                        :key="error"
                        :text="error"
                        color="error"
                        icon="$error"
                        variant="text"
                        density="compact"
                        class="alert"
                    />
                </div>
                <relay-button 
                    :disabled="!valid"
                    :is-loading="isLoading"
                    @click.prevent="onSubmit"
                >
                    Join For Free
                </relay-button>
            </v-form>
            <p class="text">Already have an account? <a href="/login">Login</a></p>
        </div>
    </split-view>
</template>

<!-- todo:
- how to render a different template
- add axios
- add favicon back
- track auth across the page
-->

<style scoped>
.form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.register {
    width: 28rem;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background-color: white;
    padding: 2rem;
    gap: 1rem;
}

.alert :deep(.v-alert__content) {
    font-size: 14px;
}

.text {
    font-size: 14px;
    margin-left: 1rem;
}

.line {
   width: 100%; 
   text-align: center; 
   border-bottom: 1px solid #000; 
   line-height: 0.1em;
   margin: 10px 0 20px; 
} 

.line span { 
    background:#fff; 
    padding:0 10px; 
}
</style>