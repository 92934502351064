<script setup>
import CreateAffiliate from './CreateAffiliate.vue';
import AffiliateBulkUpload from './AffiliateBulkUpload.vue'
import EditAffiliate from './EditAffiliate.vue';
import AffiliateDetails from './AffiliateDetails.vue';
import DeleteAffiliate from './DeleteAffiliate.vue';
import OnboardingModal from './OnboardingModal.vue';
import axios from 'axios';
import { ref } from 'vue'

//https://vuetifyjs.com/en/components/data-tables/basics/#crud-actions

const headers = [
    { title: 'Name', key: 'name', align: 'start' },
    { title: 'Referrals', key: 'numberOfReferrals', align: 'start' },
    { title: 'Reward', key: 'reward', align: 'start' },
    // { title: 'RewardAmountYTD', key: 'rewardAmountYTD', align: 'start' },
    { title: 'Affiliate Link', key: 'affiliateContractorLink', align: 'start' },
    { title: '', key: 'more', align: 'start' },
]

const affiliates = ref();
const itemsPerPage = ref(10);
const totalItems = ref(0);
const loading = ref(true);
const query = ref('');
const updateDialog = ref(false);

async function loadItems({ page, itemsPerPage, sortBy }) {
    loading.value = true;
    await axios.get('/dashboard/get-affiliates', {
        params: {
            page,
            itemsPerPage,
            sortBy,
            query: query.value ?? null,
        }
    })
    .then((response) => {
        affiliates.value = response.data.affiliates;
        totalItems.value = response.data.total;
    })
    .finally(() => {
        loading.value = false;
    });
}

function openUpdateDialog(){
    updateDialog = true;
}

function copyToClipboard(toCopy) {
    navigator.clipboard.writeText(toCopy);
}

const showOnboardingModal = ref(false);
function invalidContractorInfoModal(shouldShow) {
    showOnboardingModal.value = shouldShow;
}

async function resend(affiliateId) {
    await axios.post('/resend-new-affiliate-email', {}, {
        params: {
            affiliateId
        }
    })
}
</script>

<template>
<v-container>
    <div class="d-flex padded">
        <div class="button-container">
            <OnboardingModal :onboardingModal="showOnboardingModal" @updateModal="invalidContractorInfoModal" />
            <create-affiliate @update="loadItems({ page: 0, itemsPerPage, query })" @invalidContractorInfo="invalidContractorInfoModal"/>
            <AffiliateBulkUpload @update="loadItems({ page: 0, itemsPerPage, query })" @invalidContractorInfo="invalidContractorInfoModal"/>
        </div>
        <v-spacer />
        <v-text-field
            v-model="query"
            label="Search"
            prepend-inner-icon="mdi-magnify"
            hide-details
            single-line
        ></v-text-field>
    </div>
    <v-data-table-server
        class="table"
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items-length="totalItems"
        :items="affiliates"
        :loading="loading"
        :search="query"
        item-value="id"
        fixed
        @update:options="loadItems"
    >
        <template v-slot:item="{ item }">
            <tr>
                <td>{{ item.firstName }} {{ item.lastName }}</td>
                <td>{{ item.numberOfReferrals }}</td>
                <td>{{ item.reward }}</td>
                <td>
                    <span class="material-symbols-outlined copyable" @click="copyToClipboard(item.affiliateContractorLink)">content_copy</span>
                    <v-btn @click="resend(item.id)">Resend</v-btn>
                </td>
                <td class="view-more-menu">
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <span v-bind="props" class="material-symbols-outlined padded cursor view-more-menu-item">more_vert</span>
                        </template>

                        <v-list>
                            <v-list-item>
                                <AffiliateDetails :affiliate="item"/>
                            </v-list-item>
                            <v-list-item>
                                <EditAffiliate :affiliate="item" @update="loadItems({ page: 0, itemsPerPage, query })" />
                            </v-list-item>
                            <v-list-item>
                                <DeleteAffiliate :affiliate="item" @update="loadItems({ page: 0, itemsPerPage, query })" />
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </td>
            </tr>
        </template>
        <template v-slot:expanded-row="{ columns, item }">
            <tr>
                <td :colspan="columns.length">
                    <v-row>
                        <v-col>
                            Contact Information
                        </v-col>
                        <v-col>
                            Email: {{ item.email }}
                        </v-col>
                        <v-col>
                            Phone: {{ item.phone }}
                        </v-col>
                        <v-col>
                            Affiliate Since: {{ item.createdDate }}
                        </v-col>
                    </v-row>
                </td>
            </tr>
        </template>
        <template v-slot:item.affiliateContractorLink="props">
            <span class="material-symbols-outlined copyable" @click="copyToClipboard(props.item.affiliateContractorLink)">content_copy</span>
            <v-btn @click="console.log(props.item)">Resend</v-btn>
        </template>
    </v-data-table-server>
</v-container>

<v-dialog v-model="updateDialog">
    <v-card>
      <v-card-title>Dialog Title</v-card-title>
      <v-card-text>
        Dialog Content
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="closeDialog">Close</v-btn>
      </v-card-actions>
    </v-card>
</v-dialog>
</template>

<style scoped>
h2, h4 {
    padding: 1rem 0;
}

.copyable {
    cursor: pointer;
}

.padded {
    padding-bottom: 1rem;
}

.view-more-menu {
    vertical-align: middle;
    text-align: right;
}

.view-more-menu-item {
    cursor: pointer;
}

.button-container {
    display: flex;
    gap: 15px; /* Adjust this value to control spacing between buttons */
    align-items: center; /* Align items vertically if needed */
}
</style>