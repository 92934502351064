<script setup>
import RelayButton from './RelayButton.vue';
import { ref, reactive, defineEmits } from 'vue';
import axios from 'axios';

const props = defineProps({
    affiliate: Object,
})

const emit = defineEmits(['update']);

const dialog = ref(false);
const affiliate = reactive(props.affiliate);

function onSubmit() {
    axios.post(`/affiliate/delete?affiliateId=${affiliate.id}`)
        .finally(() => {
            emit('update');
        });
}
</script>
<template>
    <v-dialog v-model="dialog" width="512">
        <template v-slot:activator="{ props }">
            <a v-bind="props" class="view-more-menu-item">Delete</a>
        </template>
        <v-card>
            <v-card-title>
            <span class="text-h5">Confirm Delete</span>
            </v-card-title>
            <v-card-text>
                Are you sure you want to remove {{ affiliate.name }} as an affiliate?
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue-darken-1"
                @click="dialog=false">Cancel</v-btn>
            <RelayButton @click="dialog=false; onSubmit()" msg="Confirm" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style>
    .view-more-menu-item {
        cursor: pointer;
    }
</style>