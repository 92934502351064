<script setup>
import RelayButton from './RelayButton.vue';
import { ref, reactive, defineEmits, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

const router = useRouter();

const props = defineProps({
    referralId: String
})

const emit = defineEmits(['update'])

const dialog = ref(false);
const paymentLoading = ref(false);
const paymentSuccessModal = ref(false);
const paymentFailureModal = ref(false);

const paymentDetails = reactive({
    paymentAmount: 0,
    processingFee: 0,
    canMakePayment: false,
    card: {
        brand: "Card Brand",
        lastFour: "----"
    }
})

const maxRetries = 30;
var stripePaymentIntentId;
var rewardId;

onMounted(async () => {
    try {
        const { data } = await axios.get('/payments/get-payment-details', {
            params: {
                referralId: props.referralId
            }
        });
        
        paymentDetails.canMakePayment = data.canMakePayment;
        paymentDetails.paymentAmount = data.paymentAmount;
        paymentDetails.card = data.card;
        paymentDetails.processingFee = data.processingFee;

        paymentDetails.card.brand = paymentDetails.card.brand.charAt(0).toUpperCase() + paymentDetails.card.brand.slice(1);
    }
    catch(error) {
        console.log(error);
    }
})

const navigateToSettings = async () => {
    router.push('/dashboard/settings');
}

async function onSubmit() {
    try{
        const { data } = await axios.post('/payments/make-reward-payment', props.referralId, {
            headers: {
                'Content-Type': 'application/json'
                }
            }
        ); 
        
        stripePaymentIntentId = data.stripePaymentIntentId;
        rewardId = data.rewardId;
    }
    catch(error) {
        console.log(error);
        paymentProcessingFailure();
    }
    tryVerifyPaymentIntentStatus(maxRetries);
}

async function tryVerifyPaymentIntentStatus(retries) {
    try {
        const { data } = await axios.get('/payments/get-status', {
            params: {stripePaymentIntentId},
        });
        if (data.status == 'Succeeded'){
            paymentSuccess();
        } else if (data.status == 'Failed'){
            dialog.value = false;
            paymentFailure();
        } else if (retries > 0) {
            setTimeout(() => tryVerifyPaymentIntentStatus(--retries), 1000)
        } else {
            dialog.value = false;
            paymentProcessingFailure();
        };
    }
    catch(error) {
        console.log(error);
    }
}

async function sendReward() {
    try {
        await axios.post('/rewards/send-reward', {
            rewardId: rewardId,
            stripePaymentIntentId: stripePaymentIntentId
        }); 
    }
    catch(error){
        console.log(error);
    }
}

async function paymentSuccess(){
    await sendReward();
    dialog.value = false;
    paymentSuccessModal.value = true;
}

async function paymentFailure(){
    dialog.value = false;
    paymentFailureModal.value = true;
    console.log('payment failure');
}

async function paymentProcessingFailure(){
    dialog.value = false;
    paymentLoading.value=false;
    paymentFailureModal.value = true;
    console.log("payment processing failure");
    //TODO: Create a processing failure response modal
}
watch(paymentSuccessModal, (newValue, oldValue) => {
    if(newValue === false && oldValue === true){
        emit('update');
    }
});

</script>

<template>
<v-dialog v-model="dialog" width="512">
    <template v-slot:activator="{ props }">
        <v-btn v-bind="props">Mark Complete</v-btn>
    </template>
    <v-card v-if="paymentDetails.canMakePayment">
        <v-card-title>
        <span class="text-h5">Confirm Details</span>
        </v-card-title>
        <v-card-subtitle>
            A reward link will be sent to the affiliate once you submit.
        </v-card-subtitle>
        <v-card-text>
        <v-container>
            <v-row>
                Reward Amount <v-spacer /> ${{ paymentDetails.paymentAmount }}.00
            </v-row>
            <v-row class="processing-fee">
                Processing Fee <v-spacer /> + ${{ paymentDetails.processingFee.toFixed(2) }}
            </v-row>
            <v-row>
                Payment Method <v-spacer /> {{ paymentDetails.card.brand }} &#x25CF;&#x25CF;&#x25CF;&#x25CF; &#x25CF;&#x25CF;&#x25CF;&#x25CF; &#x25CF;&#x25CF;&#x25CF;&#x25CF;  {{  paymentDetails.card.lastFour }}
            </v-row>
        </v-container>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <div v-if="!paymentLoading" class="button-container">
            <v-btn
            color="blue-darken-1"
            @click="dialog=false">Cancel</v-btn>
            <RelayButton @click="onSubmit(), paymentLoading=true" msg="Submit" />
        </div>
        <div v-else>
            <v-progress-circular
                color="primary"
                indeterminate
            ></v-progress-circular>
        </div>

        </v-card-actions>
    </v-card>
    <v-card v-else>
        <v-card-title>
        <span class="text-h5">No Payment Method Found</span>
        </v-card-title>
        
        <v-container>
            A payment method is needed to send rewards, visit the settings page to update your payment method.
        </v-container>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue-darken-1"
            @click="dialog=false">Cancel</v-btn>
        <RelayButton @click="dialog=false; navigateToSettings()" msg="Settings" />
        </v-card-actions>
    </v-card>
</v-dialog>

<!--Payment Success Modal-->
<div>
    <v-dialog v-model="paymentSuccessModal" max-width="500px">
      <template v-slot:activator="{ on }"></template> <!-- No activator button -->
      <v-card>
        <!-- Your modal content goes here -->
        <v-card-title>
          Payment Success!
        </v-card-title>
        <v-card-text>
          A reward has been sent to the affiliate.
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="paymentSuccessModal=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>


<!-- Payment Failure Modal -->
<div>
    <v-dialog v-model="paymentFailureModal" max-width="500px">
      <template v-slot:activator="{ on }"></template> <!-- No activator button -->
      <v-card>
        <!-- Your modal content goes here -->
        <v-card-title>
          Your transaction cannot be completed
        </v-card-title>
        <v-card-text>
          We were unsuccessful in processing your reward payment for your <b>{{ paymentDetails.card.brand }}</b> ending in <b>{{ paymentDetails.card.lastFour }}</b>. Please try again or use another form of payment.
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="paymentFailureModal=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>



</template>

<style>
.processing-fee {
    font-size: smaller;
    color: gray;
    padding-bottom: 5%;
}
.button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>