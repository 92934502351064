<script setup>
    import { useRouter } from 'vue-router';
    import RelayButton from './RelayButton.vue';

    const router = useRouter();
    const navigateToSettings = async () => {
        router.push('/dashboard/settings');
    }
</script>

<template>
    <div class="fourohfour">
        <h1>Subscription Required</h1>
        <p> Please subscribe. In order to continue to use Relay to build your business and send rewards to your affiliates, you need to have an active subscription. </p>
        <p>Subscriptions and payment methods are managed on the settings page.</p>
        <div class="button-container">
            <RelayButton @click="navigateToSettings()" msg="Settings" />
        </div>
        
    </div>
</template>

<style scoped>
    .fourohfour {
        text-align: center;
        margin: 5rem;
    }
    .button-container {
        display: flex;
        justify-content: center;
    }
</style>