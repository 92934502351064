<script setup>
import RelayButton from './RelayButton.vue';
import { ref, reactive, defineEmits, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

const router = useRouter();

const props = defineProps({
    referralId: String
})

const dialog = ref(false);

async function onSubmit() {
    try{
        await axios.post(`/referral/complete-contractor-created?referralId=${props.referralId}`);
    }
    catch(error) {
        console.log(error);
    }
}

</script>

<template>
<v-dialog v-model="dialog" width="512">
    <template v-slot:activator="{ props }">
        <v-btn v-bind="props">Mark Complete</v-btn>
    </template>
    <v-card>
        <v-card-title>
            <span class="text-h5">Confirm Completion</span>
        </v-card-title>
        <v-card-text>
        <v-container>
            <v-row>
                Confirming will mark this referral as compelete and send an invitation email for them to join you as an affiliate.
            </v-row>
        </v-container>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue-darken-1"
            @click="dialog=false">Cancel</v-btn>
        <RelayButton @click="dialog=false; onSubmit()" msg="Confirm" />
        </v-card-actions>
    </v-card>
</v-dialog>
</template>