<template>
  <v-container style="background-color: #00AEEF;">
    <v-row class="hero">
      <h1>Referral Rewards Done Right.</h1>
      <h3>Save time and money while building your business. Relay offers attractive rewards that incentivize your
        customers to help you build your business!</h3>
    </v-row>
  </v-container>

  <v-container class="bg-gray container">
    <v-row>
      <v-spacer />
      <h1>Why our rewards ROCK!</h1>
      <v-spacer />
    </v-row>
    <v-row>
      <v-col>
        <v-card flat class="card">
          <v-card-title>
            <h2>Simplified Process</h2>
          </v-card-title>
          <v-card-text>
            <p>Relay rewards your affiliates once you've completed the job. This seamless system makes it easy to get
              rewards delivered quickly and painlessly!</p>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card flat class="card">
          <v-card-title>
            <h2>Rewards that Work</h2>
          </v-card-title>
          <v-card-text>
            <p>Relay allows your affiliates to choose the rewards they want most. Relay is partnered with thousands of
              brands and adding more daily!</p>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card flat class="card">
          <v-card-title>
            <h2>Cut Costs</h2>
          </v-card-title>
          <v-card-text>
            <p>Our rewards are designed to minimize your ad spend and eliminate money wasted in traditional advertising
              channels. Your new affiliate program is designed to cut your advertising costs and increase your marketing
              efficiency!</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <v-container class="bg-relay-blue container">
    <v-row>
      <v-col cols="12" md="6" class="col__title">
        <h2>How Rewards Work</h2>
      </v-col>
      <v-col>
        <p>Your Relay back office is simple and seamlessly allows you to send rewards through a single link.</p>
      </v-col>
      <div class="line"></div>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" class="col__title">
        <h2>Email Rewards</h2>
      </v-col>
      <v-col>
        <p>When you've completed a job in your back office, your affiliate is sent a link with the reward that you
          committed.</p>
      </v-col>
      <div class="line"></div>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" class="col__title">
        <h2>How Links Work</h2>
      </v-col>
      <v-col>
        <p>Once your customer clicks on the link, they can browse thousands stores and redeem their rewards.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.hero {
  color: white;
  padding: 8rem;
  text-align: center;
}

h1 {
  font-size: 46px;
  width: 100%;
}

h2 {
  font-size: 40px;
}

.container {
  padding: 4rem;
}

.bg-gray {
  background-color: #F4F4F4;
}

.bg-relay-blue {
  background-color: #262262;
  color: white;
}

.card {
  border: 3px solid #00AEEF;
  padding: 1rem;
  height: 100%;
}

.line {
  width: 100%;
  border-bottom: 2px white solid;
}

@media only screen and (max-width: 800px) {
  .hero {
    padding: 2rem;
  }

  h1 {
    font-size: 36px;
    line-height: normal;
    padding-bottom: 2rem;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-weight: 500;
    line-height: normal;
  }

  .container {
    padding: 2rem;
  }

  .col__title {
    padding-bottom: 0;
  }
}
</style>