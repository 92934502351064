<script setup>
import RelayButton from './RelayButton.vue';
import { ref, defineEmits, computed } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

const emit = defineEmits(['update']);
const router = useRouter();
const dialog = ref(false);
const loading = ref(false);

const uploadFile = ref(null);
const fullName = ref(false);

const defaultColumns = [
    'First Name',
    'Last Name',
    'Email',
    'Phone'
]
const fullNameColumns = [
    'Full Name',
    'Email',
    'Phone'
]

const currentColumns = computed(() => fullName.value ? fullNameColumns : defaultColumns);

const exampleImageFullName = "https://relay-static-images.s3.us-west-1.amazonaws.com/CSV-Upload-Example-FullName.png";
const exampleImageDefault = "https://relay-static-images.s3.us-west-1.amazonaws.com/CSV-Upload-Example-Default.png";
const exampleImageSource = computed(() => fullName.value ? exampleImageFullName : exampleImageDefault);

const alertMessage = ref("");
const alert = ref(false);
const companyAlert = ref(false);

const navigateToCompany = async () => {
    router.push('/dashboard/company');
}

function handleUpload(event){
    uploadFile.value = event.target.files[0];
}

async function verifyValidContractorInfo() {
    const companyDetailsStatus = (await axios.get("/validate-contractor-info")).data;
    return companyDetailsStatus;
}

async function handleButtonClick() {
  const companyDetailsStatus = await verifyValidContractorInfo();
  if (companyDetailsStatus) {
    dialog.value = true; // Only open the dialog if valid
  } else {
    emit('invalidContractorInfo', true); // Emit the event if invalid
  }
}

async function onSubmit() {
    loading.value = true;
    //Create the form data object
    const formData = new FormData();
    console.log(fullName.value);
    //Append the form data
    formData.append('csvFile', uploadFile.value);
    formData.append('fullNameUpload', fullName.value);

    try{
        await axios.post('/affiliate/bulk-upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        alert.value = false;
        loading.value = false;
        dialog.value = false;
        emit("update");
    } catch(error){
        loading.value = false;
        if (error.response && error.response.data && error.response.data.userErrorMessage){
            alertMessage.value = error.response.data.userErrorMessage;
            alert.value = true;
        }
        else{
            alertMessage.value = "Error processing affiliates, please review your file and try again."
            alert.value = true;
        }
    }
}
</script>

<template>
<RelayButton class="button" @click="handleButtonClick()" v-bind="props">Bulk Upload Affiliates</RelayButton>
<v-dialog v-model="dialog" width="512">
    <v-card>
        <v-card-title>
        <span class="text-h5">Upload Affiliates</span>
        </v-card-title>
        <v-card-subtitle class="subtitle">
            Creating a new affiliate generates a custom link and sends an invite email to the affiliate allowing them to share your business and earn rewards.
        </v-card-subtitle>
        <v-card-text>
            <v-alert
            v-model="companyAlert"
            title="Company details not filled out"
            type="warning"
        >An email will not be sent to your affiliate unless you have filled out your company information on the <span class="route-link" @click="navigateToCompany()">Company page</span>.</v-alert>
        
        <p>Upload a CSV file with these column headers:</p>
        <p class="smallerText">Uploads are up to 1000 affiliates</p>
        <v-container>
            <ul>
                <li v-for="column in currentColumns" :key="column"> {{ column }}</li>
            </ul>
        </v-container>

        <p>Example:</p>
        <v-container>
            <v-img :width="400" aspect-ratio="4/1" cover :src="exampleImageSource"></v-img>
        </v-container>
        
        <v-container>
            <v-row>
                <p
                class="smallerText">
                    <v-tooltip
                    activator="parent"
                    location="end">
                    <span class="tooltip-text">
                        Use one or two columns for your affiliates' names.
                    </span>
                    </v-tooltip>
                    Name Options
                    <v-icon 
                        v-bind="attrs" 
                        v-on="on" 
                        class="info-icon"
                        >
                        mdi-information
                    </v-icon>
                </p>
            </v-row>
            <v-row>
                <v-btn-toggle
                    v-model="fullName"
                    color="primary"
                    group
                    class="button-group">
                    <v-btn :value="false">
                        First Name/Last Name
                    </v-btn>
                    <v-btn :value="true">
                        Full Name
                    </v-btn>
                </v-btn-toggle>
            </v-row>
            
            <v-row>
                <v-file-input label="Upload Affiliate CSV File" accept=".csv" @change="handleUpload"></v-file-input>
            </v-row>
            <v-alert
                v-model="alert"
                :text="alertMessage"
                title="Error uploading affiliates"
                type="error"
                ></v-alert>
        </v-container>
        </v-card-text>
        <v-card-actions v-if="!loading">
            <v-spacer></v-spacer>
            <v-btn
                color="blue-darken-1"
                @click="dialog=false">Cancel</v-btn>
            <RelayButton @click="onSubmit()" msg="Submit" />
        </v-card-actions>
        <v-card-actions v-else>
            <v-spacer></v-spacer>
            <v-progress-circular
                color="primary"
                class="text-right"
                indeterminate
                ></v-progress-circular>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<style scoped>
    .button {
        width: fit-content;
    }
    .smallerText {
        font-size: small;
    }
    .button-group{
        margin-bottom: 10%;
        margin-top: 3%;
    }
    .subtitle {
        white-space: normal;
    }
    .route-link{
        text-decoration: underline;
        cursor: pointer;
    }
    .tooltip-text{
        font-size: small;
    }
    .info-icon {
        cursor: pointer;
        margin-left: 8px; /* Adjust space between text and icon */
        font-size: 18px; /* Adjust icon size */
        color: black;
    }
</style>