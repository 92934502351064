import './assets/main.css'

import { createApp, markRaw } from 'vue';
import { createRouter, createWebHistory } from 'vue-router'
import { createPinia } from 'pinia'
import Home from './views/Home.vue'
import Rewards from './views/Rewards.vue'
import AboutUs from './views/AboutUs.vue'
import NotFound from './views/NotFound.vue'
import Login from './views/Login.vue'
import Contact from './views/Contact.vue'
import WhyRelay from './views/WhyRelay.vue'
import Pricing from './views/Pricing.vue'
import Join from './views/Join.vue'
import DashboardContainer from './views/DashboardContainer.vue'
import RelayDashboard from './components/RelayDashboard.vue'
import Referrals from './components/Referrals.vue'
import Affiliates from './components/Affiliates.vue'
import Company from './views/Company.vue'
import Settings from './views/Settings.vue'
import RelayBusinessCard from './views/RelayBusinessCard.vue'
import Help from './components/Help.vue';
import { userStore } from './scripts/store.js'

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives' //maybe we don't need all this stuff
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { aliases } from 'vuetify/iconsets/mdi-svg'
import { mdi } from 'vuetify/iconsets/mdi'

import axios from 'axios';

// might try subdomains
// https://stackoverflow.com/questions/19016553/add-subdomain-to-localhost-url

const routes = [
  { path: '/', component: Home },
  { path: '/rewards', component: Rewards },
  { path: '/about', component: AboutUs },
  { path: '/login', component: Login, meta: { clean: true, auth: true } },
  { path: '/join', component: Join, meta: { clean: true, auth: true } },
  { path: '/contact', component: Contact, meta: { contact: true } },    
  { path: '/why-relay', component: WhyRelay },
  { path: '/pricing', component: Pricing },
  { path: '/dashboard',
    component: DashboardContainer, 
    children: [
      { path: '', component: RelayDashboard, meta: { requiresAuth: true, requiresSubscription: true, title: "Dashboard" } },
      { path: 'referrals', component: Referrals, meta: { requiresAuth: true, requiresSubscription: true, title: "Referrals" } },
      { path: 'affiliates', component: Affiliates, meta: { requiresAuth: true, requiresSubscription: true, title: "Affiliates" } },
      { path: 'company', component: Company, meta: { requiresAuth: true, requiresSubscription: true, title: "Company Details" } },
      { path: 'settings', component: Settings, meta: { requiresAuth: true, title: "Settings" } },
      { path: 'help', component: Help, meta: { requiresAuth: true, title: "Help" } },
      { path: 'admin', component: Admin, meta: { requiresAuth: true, title: "Admin" } },
      { path: 'noSubscription', component: SubscriptionRequired, meta: {requiresAuth: true} },
    ],
    meta: { requiresAuth: true } 
  },
  { 
    path: '/:relayCode(r-.{8})', 
    component: RelayBusinessCard, 
    meta: { clean: true },
    beforeEnter: (to, from, next) => {
      VerifyClickIncrement(to.params.relayCode);
      next();
    }
  },
  { path: '/forbidden', component: RelayForbidden },
  { path: '/:pathMatch(.*)*', component: NotFound }
]

async function IncrementClicksAndSetExpiration(key, time, relayCode) {
  await axios.post(`/relay/increment-link-clicks?relayCode=${relayCode}`);

  const expirationMinutes = 60;
  const expirationMS = expirationMinutes * 60 * 1000;
  const expirationTime = time + expirationMS;
  localStorage.setItem(relayCode, expirationTime.toString());
}

function VerifyClickIncrement(key) {
  const expirationString = localStorage.getItem(key);
  const currentTime = new Date().getTime();
  if (!expirationString) {
    IncrementClicksAndSetExpiration(key, currentTime, key);
  }
  else {
    const expiration = new Date(parseInt(expirationString)).getTime();
    if (currentTime > expiration){
      IncrementClicksAndSetExpiration(key, currentTime, key);
    }
  }

}

// https://jasonwatmore.com/post/2022/05/26/vue-3-pinia-jwt-authentication-tutorial-example

const router = createRouter({
  history: createWebHistory(),
  routes,
})

import App from './App.vue'
import RelayForbidden from './views/RelayForbidden.vue';
import SubscriptionRequired from './components/SubscriptionRequired.vue';
import Admin from './views/Admin.vue';

window.Vue = require('vue').default;

const pinia = createPinia()
pinia.use(({ store }) => {
  store.$router = markRaw(router)
});

const app = createApp(App)
app.config.globalProperties.window = window

app.use(pinia)
app.use(router)

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const store = userStore();

axios.interceptors.response.use(
  response => response,
  error => {
    console.log(error)
    if (error.response.status == 401 && !error.request.responseURL.includes('/logout') && !error.request.responseURL.includes('/login-relay')) {
      store.logout();
      router.push('/login');
    }
    console.log(store.errorMessage);
    store.errorMessage = error.message;

    return Promise.reject(error);
  });

const vuetify = createVuetify({
  components,
  directives,
  defaults: {
    VFileInput: { variant: 'outlined' },
    VTextarea: { variant: 'outlined' },
    VTextField: { variant: 'outlined' },
    VSelect: { variant: 'outlined' },
    VBtn: { variant: 'outlined' },
  },
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  display: {
    mobileBreakpoint: 'sm'
  }
})

app.use(vuetify)

router.beforeEach(async (to) => {
  const store = userStore()
  
  store.isCleanView = to.meta.clean;
  store.isContactView = to.meta.contact;

  if (to.meta.requiresAuth && !store.isAuthenticated && !(await store.login())) return '/login';

  // TODO: It probably makes more sense to just have some sort of authorization attribute on the controller with a component returned based on the error? Or just have it as a boolean in the requests here?
  if(to.meta.requiresSubscription){
    const firstTime = to.query.joined;
    if(!firstTime){
      const currentTime = new Date().getTime();
      const expirationMinutes = 60;
      const expirationMS = expirationMinutes * 60 * 1000;
      let validSubscription = ((store.subscribed + expirationMS) > currentTime);
      if(!validSubscription){
        await store.setSubscriptionStatus();
      }
      validSubscription = ((store.subscribed + expirationMS) > currentTime);
      if(!validSubscription){
        return '/dashboard/noSubscription';
      }
    }
  }

  if (store.isAuthenticated && to.meta.auth) return '/dashboard';
})

app.mount('#app')