<script setup>
import axios from 'axios';
import { ref } from 'vue';
import { userStore } from '@/scripts/store'

const store = userStore();
const dialog = ref(false);
const agreement = ref(false);
const processingErrorModal = ref(false);

async function deleteAccount(){
    try {
        await axios.post('/delete');
    } catch (e) {
        agreement.value = false;
        dialog.value = false;
        processingErrorModal.value = true;
        console.log(e)
    } finally {
        store.logout()
    }
};
</script>

<template>
    <v-card class="card" max-width="1024" flat>
        <v-card-title>Delete Account</v-card-title>
        <v-card-text>
            <v-container>
                <v-btn @click="dialog=true">Delete</v-btn>
            </v-container>
        </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" width="512">
        <v-card>
            <v-card-title>
                <span class="text-h5">Delete Account</span>
            </v-card-title>
            <v-card-subtitle>
                Delete all account information
            </v-card-subtitle>
            <v-card-text>
                Deleting your account will delete all data surrounding affiliates, referrals, rewards and transactions - and <b>cannot be undone</b>.
                Are you sure you wish to proceed?
            </v-card-text>
            <v-checkbox v-model="agreement" label="I understand my account will be deleted and this cannot be undone"></v-checkbox>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    @click="dialog=false">Cancel</v-btn>
                <v-btn
                    color="blue-darken-1"
                    :disabled="!agreement"
                    @click="deleteAccount(), dialog=false">Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- Error Modal -->
    <div>
        <v-dialog v-model="processingErrorModal" max-width="500px">
        <template v-slot:activator="{ on }"></template> <!-- No activator button -->
        <v-card>
            <v-card-title>
                Processing Error
            </v-card-title>
            <v-card-text>
                Account in incorrect state and requires assistance. Reach out to have account deleted.
            </v-card-text>
            <v-card-actions>
            <v-btn color="primary" @click="processingErrorModal=false">Close</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>
</template>

<style scoped>
.card {
    margin-top: 1rem;
}
</style>