<script setup>
import axios from 'axios';
import { onMounted, reactive, ref, computed } from 'vue';

const companyData = reactive({
  name: '',
  description: '',
  phone: '',
  email: '',
  website: '',
  defaultReferralBonus: 0,
  twitter: '',
  facebook: '',
  linkedIn: '',
  instagram: '',
  logo: '',
  emailTemplate: '',
  emailIntro: '',
});
const snackbarSaved = ref(false);
const timeout = 2000;
const infoModal = ref(false);
const infoTitle = ref("");
const infoText = ref("");
const rewardAlert = ref(false);
const minValue = 5;
const maxValue = 2000;
const fileTooLargeMessage = "Image file size too large";
const logoFile = ref(null);

onMounted(async () => {
  await loadData();
});

async function loadData() {
  try {
    const { data } = await axios.get('/company/details');
    companyData.name = data.company.name ?? companyData.name;
    companyData.description = data.company.description ?? companyData.description;
    companyData.phone = data.company.phone ?? companyData.phone;
    companyData.email = data.company.email ?? companyData.email;
    companyData.contactName = data.company.contactName ?? companyData.contactName;
    companyData.website = data.company.website ?? companyData.website;
    companyData.defaultReferralBonus = data.company.defaultReferralBonus ?? companyData.defaultReferralBonus;
    companyData.facebook = data.company.facebook ?? companyData.facebook;
    companyData.linkedIn = data.company.linkedIn ?? companyData.linkedIn;
    companyData.instagram = data.company.instagram ?? companyData.instagram;
    if (data.company.logoPath != null) {
      companyData.logoPath = data.company.logoPath + "?timestamp=" + Date.now().toString();
    }
    companyData.emailTemplate = data.emailTemplate ?? companyData.emailTemplate;
    companyData.emailIntro = data.company.emailIntro ?? companyData.emailIntro;
  } catch (e) {
    console.log(e);
  }
}

function validateRewardValue(){
  if (companyData.defaultReferralBonus > maxValue || companyData.defaultReferralBonus < minValue){
    rewardAlert.value = true;
  }
}

function handleLogoFileUpload(event) {
  logoFile.value = event.target.files[0]; // Get the first file selected
}

async function save() {
  //Create a form data object
  const formData = new FormData();

  // Append company data
  formData.append('name', companyData.name);
  formData.append('description', companyData.description);
  formData.append('phone', companyData.phone);
  formData.append('emailIntro', companyData.emailIntro);
  formData.append('email', companyData.email);
  formData.append('contactName', companyData.contactName);
  formData.append('website', companyData.website);
  formData.append('defaultReferralBonus', companyData.defaultReferralBonus);
  formData.append('instagram', companyData.instagram);
  formData.append('logo', logoFile.value); // Append the logo file
  formData.append('twitter', companyData.twitter);
  formData.append('facebook', companyData.facebook);
  formData.append('linkedIn', companyData.linkedIn);
  validateRewardValue();
  try {
    await axios.post('/company/save', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (rewardAlert.value === true){
      rewardAlert.value = false;
    }
    snackbarSaved.value = true;
    await loadData();
  } catch (e) {
    infoTitle.value= "Error";
    if (e.response.data.includes(fileTooLargeMessage)){
      infoText.value = fileTooLargeMessage;
    }
    else{
      infoText.value= "We were unable to process your request";
    }
    infoModal.value = true;
    console.log(e);
  };
}

const dialog = ref(false);

const processedEmailHtml = computed(() => {
  let html = companyData.emailTemplate;
  const values = {
    customerName: '[Your customer\'s name]',
    companyName: companyData.name ?? '[Your company name here]',
    introText: companyData.emailIntro ?? '[Your custom email intro here]',
    link: '[affiliate link]',
    reward: '[Reward Value]',
    headerImageUrl: companyData.logoPath ?? null,
    contactName: companyData.contactName ?? '[Contact Name]',
    contactPhone: companyData.phone ?? '[Phone Number]',
    contactEmail: companyData.email ?? '[Email]',
  }
  for (let key in values) {
    html = html.replace(new RegExp('{{ params.' + key + ' }}', 'g'), values[key]);
  }
  return html;
});
</script>
<template>
  <v-container fluid class="company-container">
    <v-card justify="center" flat max-width="1024">
      <v-card-title>
      </v-card-title>
      <v-container>
        <v-row>
          <v-col>
            <v-text-field label="Company Name" v-model="companyData.name"></v-text-field>
            <v-textarea label="Description" v-model="companyData.description"></v-textarea>
            <v-textarea label="Custom Email Intro" v-model="companyData.emailIntro"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="custom-padding">
                <v-text-field 
                label="Default Reward Amount - Required" 
                hint="Rewards must be between $5-$2000"
                class="custom-padding"
                v-model="companyData.defaultReferralBonus"
                type="number"></v-text-field>
                <v-text-field label="Contact Name" v-model="companyData.contactName"></v-text-field>
              <v-text-field label="Email - Required" v-model="companyData.email"></v-text-field>
              <v-text-field label="Phone" v-model="companyData.phone" type="tel"></v-text-field>
              <v-file-input label="Upload Logo" accept="image/*" @change="handleLogoFileUpload"></v-file-input>
              <div v-if="companyData.logoPath != null">
                <div class="logo-preview-text">Logo:</div>
                <div class="logo-img-container">
                  <v-img width="150" :height="150" aspect-ratio="1/1" :src="companyData.logoPath"></v-img>
                </div>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="custom-padding">
              <v-text-field label="Instagram" v-model="companyData.instagram"></v-text-field>
              <v-text-field label="LinkedIn" v-model="companyData.linkedIn"></v-text-field>
              <v-text-field label="Facebook" v-model="companyData.facebook"></v-text-field>
              <v-text-field label="Website" v-model="companyData.website"></v-text-field>
              <v-alert
                v-model="rewardAlert"
                title="Invalid reward amount"
                type="error"
                >Amount must be between $5 and $2000</v-alert>
              <v-card-actions>
                <v-dialog v-model="dialog" width="660">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props">View Email Preview</v-btn>
                  </template>
                  <v-card>
                    <v-card-text>
                      <div v-html="processedEmailHtml"></div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn color="blue-darken-1" @click="dialog = false">Close</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-btn color="#00AEEF" variant="flat" @click="save()">Save Changes</v-btn>
              </v-card-actions>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
  <v-snackbar v-model="snackbarSaved" :timeout="timeout" color="success" min-width="0">
    <strong>
      Saved
    </strong>
  </v-snackbar>

  <!--Error response modal-->
  <div>
    <v-dialog v-model="infoModal" max-width="500px">
      <template v-slot:activator="{ on }"></template> <!-- No activator button -->
      <v-card>
        <v-card-title>
          {{ infoTitle }}
        </v-card-title>
        <v-card-text>
          {{ infoText }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="infoModal=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.company-container {
  margin-bottom: 4rem;
}

.logo-center {
  display: flex;
  justify-content: center;
}

.logo-img-container {
  border: 1px solid #000;
  /* Add border styles here */
  border-radius: 8px;
  /* Optional: Add border radius for rounded corners */
  overflow: hidden;
  /* Optional: Ensure the image fits within the border */
  display: flex;
  justify-content: center;
}

.logo-preview-text {
  text-align: left;
  align-content: flex-start;
}
.custom-padding > * {
  padding-bottom: 10px; /* Adjust as needed */
}
</style>