<script setup>
import { reactive, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router';
import axios from 'axios';

import OnboardingModal from './OnboardingModal.vue';
import IntroVideoTutorialModal from './IntroVideoTutorialModal';

const dashboardData = reactive({
  totalReferrals: 0,
  totalReferralsCompleted: 0,
  totalAffiliates: 0,
  totalClicks: 0,
  affiliates: [],
  referrals: []
})
const nameSpace = " ";

const showTutorialModal = ref(false);
const closeModal = () => {
  showTutorialModal.value = false;
};

const route = useRoute();
onMounted(async () => {
  try {
    const { data } = await axios.get('/dashboard/data');
    dashboardData.totalReferrals = data.totalReferrals;
    dashboardData.totalReferralsCompleted = data.totalReferralsCompleted;
    dashboardData.totalAffiliates = data.totalAffiliates;
    dashboardData.totalClicks = data.totalClicks;
    dashboardData.affiliates = data.affiliates;
    dashboardData.referrals = data.referrals;

    if (route.query.joined){
      showTutorialModal.value = true;
    }
  } catch (e) {
    console.log(e);
  }
});
</script>

<template>
  <div class="bg-gray full">
    <v-container>

      <IntroVideoTutorialModal :onboardingModal="showTutorialModal" @close="closeModal" />

      <h4>Quick Stats</h4>
      <v-row>
        <v-col>
          <v-card height="250" :class="['d-flex justify-center align-center elevation-4 stat']">
            <v-col>
              <v-card-title class="title">{{ dashboardData.totalAffiliates }}</v-card-title>
              <v-card-subtitle class="subtitle">Total Affiliates</v-card-subtitle>
            </v-col>
          </v-card>
        </v-col>

        <v-col>
          <v-card height="250" :class="['d-flex justify-center align-center elevation-4 stat']">
            <v-col>
              <v-card-title class="title">{{ dashboardData.totalClicks }}</v-card-title>
              <v-card-subtitle class="subtitle">Total Affiliate Links Clicked</v-card-subtitle>
            </v-col>
          </v-card>
        </v-col>
        
        <v-col>
          <v-card height="250" :class="['d-flex justify-center align-center elevation-4 stat']">
            <v-col>
              <v-card-title class="title">{{ dashboardData.totalReferrals }}</v-card-title>
              <v-card-subtitle class="subtitle">Total People Referred</v-card-subtitle>
            </v-col>
          </v-card>
        </v-col>

        <v-col>
          <v-card height="250" :class="['d-flex justify-center align-center elevation-4 stat']">
            <v-col>
              <v-card-title class="title">{{ dashboardData.totalReferralsCompleted }}</v-card-title>
              <v-card-subtitle class="subtitle">Total Referrals Closed</v-card-subtitle>
            </v-col>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="bottom-row">
        <v-col>
          <div class="d-flex align-content">
            <h4>Referrals</h4>
            <v-spacer />
            <a @click="$router.push('/dashboard/referrals')">View More</a>
          </div>
          <v-card :class="['elevation-4']">
            <v-list v-if="dashboardData.referrals.length > 0">
              <template v-for="referral in dashboardData.referrals">
                <v-divider class="short" />
                <v-list-item>
                  <template v-slot:prepend>
                    <span class="material-symbols-outlined padded">account_circle</span>
                  </template>
                  <v-list-item-title v-text="(referral.firstName + nameSpace + referral.lastName)"></v-list-item-title>
                  <template v-slot:append>
                    <v-menu>
                      <template v-slot:activator="{ props }">
                        <span v-bind="props" class="material-symbols-outlined padded cursor">more_vert</span>
                      </template>

                      <v-list>
                        <v-list-item v-for="(item, index) in [{ title: 'View More', link: '/dashboard/referrals' }]"
                          :key="index">
                          <v-list-item-title><a @click="$router.push(item.link)">{{ item.title
                              }}</a></v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-list-item>
              </template>
              <v-divider class="short" />
            </v-list>
            <v-card flat v-else>
              <v-card-title>You don't have any referrals yet.</v-card-title>
            </v-card>
          </v-card>
        </v-col>
        <v-col>
          <div class="d-flex align-content">
            <h4>Affiliates</h4>
            <v-spacer />
            <a @click="$router.push('/dashboard/affiliates')">View More</a>
          </div>
          <v-card :class="['elevation-4']">
            <v-list v-if="dashboardData.affiliates.length > 0">
              <template v-for="affiliate in dashboardData.affiliates">
                <v-divider class="short" />
                <v-list-item>
                  <template v-slot:prepend>
                    <span class="material-symbols-outlined padded">account_circle</span>
                  </template>
                  <v-list-item-title v-text="(affiliate.firstName + nameSpace + affiliate.lastName)"></v-list-item-title>
                  <template v-slot:append>
                    <v-menu>
                      <template v-slot:activator="{ props }">
                        <span v-bind="props" class="material-symbols-outlined padded cursor">more_vert</span>
                      </template>

                      <v-list>
                        <v-list-item v-for="(item, index) in [{ title: 'View More', link: '/dashboard/affiliates'}]"
                          :key="index">
                          <v-list-item-title><a @click="$router.push(item.link)">{{ item.title
                              }}</a></v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-list-item>
              </template>
              <v-divider class="short" />
            </v-list>
            <v-card flat v-else>
              <v-card-title>You don't have any affiliates yet.</v-card-title>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
a {
  line-height: 3;
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}

.full {
  height: 100%;
}

h2,
h4 {
  padding: 1rem 0;
}

.title {
  font-size: 60px;
  align-self: center;
}

.title,
.subtitle {
  text-align: center;
}

.subtitle {
  font-weight: bold;
}

.short {
  margin-left: 1rem;
  margin-right: 1rem;
}

.padded {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media only screen and (max-width: 800px) {
  .stat {
    height: 160px !important;
    width: 160px;
  }

  .bottom-row {
    display: block;
  }
}
</style>