<script setup>
import RelayButton from './RelayButton.vue';
import { ref, reactive, onMounted, watch } from 'vue';
import axios from 'axios';
import { userStore } from '@/scripts/store';

const store = userStore();

const emit = defineEmits(['update']);
const dialog = ref(false);
const subscription = reactive({
  name: "",
  price: 0,
  id: 0,
});
const subscriptionLoading = ref(false);
const maxRetries = 30;
const paymentSuccessModal = ref(false);
const paymentFailureModal = ref(false);

const props = defineProps({
  buttonText: String
});

onMounted(async () => {
  try {
    const { data } = await axios.get('/payments/get-subscriptions');
    const basicPlan = data.subscriptions[0] //TODO: This will need to change when we have more than one subscription
    subscription.name = basicPlan.name;
    subscription.price = basicPlan.price;
    subscription.id = basicPlan.id;
  } catch (e) {
    console.log(e);
  }
});

async function onSubmit() {
  const date = new Date();
  const utcDate = date.toUTCString();
  const timestamp = new Date(utcDate);
  timestamp.setMinutes(timestamp.getMinutes() - 2);
  try{
    await axios.post(`/payments/upgrade-subscription?relaySubscriptionId=${subscription.id}`)

    if(!store.subscribed){
      tryVerifySubscriptionStatus(maxRetries, timestamp);
    }
    else{
      paymentSuccess();
      store.setSubscriptionStatus();
    }
  }catch(error){
    console.log(error);
  }
}

async function tryVerifySubscriptionStatus(retries, timestamp) {
    try {
        const { data } = await axios.get('/payments/get-subscription-payment-status', {
            params: {timestamp},
        });
        if (data.status){
            paymentSuccess();
        } else if (retries > 0) {
            setTimeout(() => tryVerifySubscriptionStatus(--retries, timestamp), 1000)
        } else {
            paymentFailure();
        };
    }
    catch(error) {
        console.log(error);
    }
}

function paymentSuccess(){
  dialog.value = false;
  subscriptionLoading.value = false;
  paymentSuccessModal.value = true;
  store.setSubscriptionStatus();
}

function paymentFailure(){
  dialog.value = false;
  paymentFailureModal.value = true;
  subscriptionLoading.value = false;
  store.setSubscriptionStatus();
}

watch(paymentSuccessModal, (newValue, oldValue) => {
    if(newValue === false && oldValue === true){
        emit('update');
    }
});

watch(paymentFailureModal, (newValue, oldValue) => {
    if(newValue === false && oldValue === true){
        emit('update');
    }
});

</script>

<template>
  <v-dialog v-model="dialog" max-width="512">
    <template v-slot:activator="{ props }">
      <v-btn variant="flat" color="blue-darken-1" v-bind="props">{{ buttonText }}</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Upgrade Subscription</span>
      </v-card-title>
      <v-card-subtitle>
        Subscription Plan Details
      </v-card-subtitle>
      <v-card-text>
        <v-container>
          <v-row>
            Plan: {{ subscription.name }}
          </v-row>
          <v-row>
            Monthly Payment: ${{ subscription.price }}.00
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="button-container">
        <div v-if="!subscriptionLoading">
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false">Cancel</v-btn>
          <v-btn variant="flat" color="blue-darken-1" @click="subscriptionLoading=true, onSubmit();">Confirm</v-btn>
        </div>
        <div v-else>
            <v-progress-circular
                color="primary"
                indeterminate
            ></v-progress-circular>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <!--Payment Success Modal-->
  <div>
      <v-dialog v-model="paymentSuccessModal" max-width="500px">
        <template v-slot:activator="{ on }"></template> <!-- No activator button -->
        <v-card>
          <!-- Your modal content goes here -->
          <v-card-title>
            Thank you!
          </v-card-title>
          <v-card-text>
            You are now subscribed!
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="paymentSuccessModal=false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>


  <!-- Payment Failure Modal -->
  <div>
      <v-dialog v-model="paymentFailureModal" max-width="500px">
        <template v-slot:activator="{ on }"></template> <!-- No activator button -->
        <v-card>
          <!-- Your modal content goes here -->
          <v-card-title>
            Your transaction cannot be completed
          </v-card-title>
          <v-card-text>
            We were unsuccessful in processing your subscription. Please try again or use another form of payment.
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="paymentFailureModal=false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<style scoped>
  .button {
    width: fit-content;
  }
  .button-container{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
</style>