<template>
  <div class="container">
    <v-carousel class="carousel" hide-delimiters eager rounded=0 height="auto" :style="'max-height: 600'">
      <template v-slot:prev="{ props }">
        <span class="material-symbols-outlined white" @click="props.onClick">arrow_back_ios</span>
      </template>
      <template v-slot:next="{ props }">
        <span class="material-symbols-outlined white" @click="props.onClick">arrow_forward_ios</span>
      </template>
      <v-fade-transition leave-absolute>
        <v-carousel-item :style="'max-height: inherit'" cover src="/img/Carousel1.jpeg" eager
          gradient='linear-gradient(to right, #FF512F, #F09819)' />
      </v-fade-transition>
      <v-fade-transition leave-absolute>
        <v-carousel-item :style="'max-height: inherit'" cover src="/img/Carousel2.jpeg" eager
          gradient='linear-gradient(to right, #FF512F, #F09819)' />
      </v-fade-transition>
      <v-fade-transition leave-absolute>
        <v-carousel-item :style="'max-height: inherit'" cover src="/img/Carousel3.jpeg" eager
          gradient='linear-gradient(to right, #FF512F, #F09819)' />
      </v-fade-transition>
      <v-fade-transition leave-absolute>
        <v-carousel-item :style="'max-height: inherit'" cover src="/img/Carousel4.jpeg" eager
          gradient='linear-gradient(to right, #FF512F, #F09819)' />
      </v-fade-transition>
      <v-fade-transition leave-absolute>
        <v-carousel-item :style="'max-height: inherit'" cover src="/img/Carousel5.jpeg" eager
          gradient='linear-gradient(to right, #FF512F, #F09819)' />
      </v-fade-transition>
    </v-carousel>
    <v-card class="content rounded-0" flat>
      <div class="inner">
        <h1>Refer, Reward, Repeat</h1>
        <h3>Get more referrals by turning satisfied customers into an affiliate safesforce.</h3>
        <div><v-btn @click="$router.push('/join')" variant="flat" color="white" style="margin-bottom:1rem;">Get
            Started</v-btn></div>
      </div>
    </v-card>
  </div>
</template>

<style scoped>
.white {
  color: white;
  cursor: pointer;
  z-index: 100;
}

.carousel {
  max-height: 600px;
}

.carousel * {
  max-height: inherit;
}

.space {
  height: 1rem;
}

.container {
  width: 100%;
  position: relative;
}

.content {
  color: white;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00000066;
}

.inner {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 48px;
}

h1 {
  font-size: 96px;
  font-weight: 700;
}

h3 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 800px) {
  .inner {
    width: 100%;
    padding: 0 1rem;
    margin: auto;
  }

  .carousel {
    height: 100px;
  }

  h1 {
    font-size: 36px;
    line-height: 52px;
    margin: 1rem 0;
  }

  h3 {
    font-size: 16px;
  }

  .content {
    width: unset;
    left: 0;
    top: 0;
    position: relative;
  }

  .space {
    height: 0;
  }
}
</style>