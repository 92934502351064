<script setup>
    import { ref, watch, defineProps, defineEmits } from 'vue';
    import { useRouter } from 'vue-router';

    const props = defineProps({
        onboardingModal: Boolean,
        required: true,
    });

    const emit = defineEmits(['updateModal']);

    const showModal = ref(props.onboardingModal);

    watch(() => props.onboardingModal, (newVal) => {
        showModal.value = newVal;
    });

    watch(() => showModal.value, (update) => {
        if (update == false){
            emit('updateModal', update); // Emit event when dialog is closed
        };
    });

    const router = useRouter();
    const navigateTo = async (settingsPage) => {
        router.push(`/dashboard/${settingsPage}`);
    }
    
</script>

<template>
  <div>
    <v-dialog v-model="showModal" max-width="500px" @update:showModal="updateModal">
      <template v-slot:activator="{ on }"></template> <!-- No activator button -->
      <v-card>
        <v-card-title class="onboarding-title">
            Welcome to Relay!
        </v-card-title>
        <v-card-text>
            <p class="onboarding-text">
                Begin by entering your <span class="font-weight-bold">company information</span> and a <span class="font-weight-bold">payment method</span> to customize your communications and send rewards. 
            </p>
            <p>
                Please enter this information to unlock affiliate creation. Visit Company and Account Settings to enter this information.
            </p class="onboarding-text">
            <div class="button-container">
                <v-btn color="primary" @click="navigateTo('company')">Company Settings</v-btn>
                <v-btn color="primary" @click="navigateTo('settings')">Account Settings</v-btn>
            </div>
        </v-card-text>
        <v-card-actions class="align-right">
          <v-btn @click="showModal=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>

<style scoped>
    .button-container {
        display: flex;
        gap: 5px; /* Adjust the gap as needed */
        padding-top: 15px;
    }
    .font-weight-bold{
        font-weight: bold;
    }
    .onboarding-text{
        margin-bottom: 1em; /* Adjust as needed for desired spacing */
    }
    .onboarding-title{
        text-align: center;
    }
    .align-right {
        display: flex;
        justify-content: flex-end;
    }

    /* Mobile view styles */
    @media (max-width: 600px) {
        .button-container {
            flex-direction: column; /* Stack buttons vertically on small screens */
            align-items: center; /* Center buttons horizontally in column layout */
        }
    }
</style>