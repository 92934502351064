<script setup>
import SendRewardModal from '@/components/SendRewardModal.vue';
import CompleteReferral from '@/components/MarkCompleteContractorCreated.vue';
import CreateReferral from '@/components/CreateReferral.vue';
import OnboardingModal from './OnboardingModal.vue';
import axios from 'axios';
import { ref, computed } from 'vue'

const headers = [
    { title: 'Date Received', align: 'start', key: 'createdDate' },
    { title: 'Name', align: 'start', key: 'fullName' },
    { title: 'Affiliate', align: 'start', key: 'affiliate' },
    { title: 'Job Status', align: 'start', key: 'status'}]

const referrals = ref([]);
const itemsPerPage = ref(10);
const totalItems = ref(0);
const loading = ref(true);
const query = ref('');
const contractorId = ref('');

function loadItems({ page, itemsPerPage, sortBy }) {
    axios.get('/dashboard/get-referrals', {
    params: {
            page,
            itemsPerPage,
            sortBy,
            query: query.value ?? null,
        }
    })
    .then(({ data }) => {
        referrals.value = data.referrals;
        totalItems.value = data.total;
        contractorId.value = data.contractorId;
    })
    .finally(() => {
        loading.value = false
    });
}

const processedReferrals = computed(() => {
    return referrals.value.map(referral => ({
        ...referral,
        fullName: `${referral.firstName} ${referral.lastName}`
    }));
});


const showCompleteModal = ref(false);
const referralId = ref('');
const isLoading = ref(false);

const onClickComplete = (id) => {
    referralId.value = id;
    showCompleteModal.value = true;
};

const sendReward = async () => {
    isLoading.value = true;
    try {
        await axios.post('/payments/reward-payment', null, {
            params: {
                contractorId: contractorId.value,
                referralId: referralId.value,
            },
        });
    } catch(e) {
        console.log(e);
    } finally {
        isLoading.value = false;
    }
};

async function deleteReferral(referralId) {
    try {
        await axios.post('/delete-referral', null, {
            params: {
                referralId,
            },
        });

        loadItems({ page: 0, itemsPerPage: itemsPerPage.value, query: query.value });
    } catch (e) {
        console.log(e);
    }
}

const showOnboardingModal = ref(false);
function invalidContractorInfoModal(shouldShow) {
    showOnboardingModal.value = shouldShow;
}
</script>

<template>
    <v-container>
        <div class="d-flex padded">
            <OnboardingModal :onboardingModal="showOnboardingModal" @updateModal="invalidContractorInfoModal" />
            <create-referral @update="loadItems({ page: 0, itemsPerPage, query })" @invalidContractorInfo="invalidContractorInfoModal" />
            <v-spacer />
            <v-text-field
                v-model="query"
                label="Search"
                prepend-inner-icon="mdi-magnify"
                hide-details
                single-line
            ></v-text-field>
        </div>
        <v-data-table-server
            class="table"
            v-model:items-per-page="itemsPerPage"
            :headers="headers"
            :items-length="totalItems"
            :items="processedReferrals"
            :loading="loading"
            :search="query"
            item-value="id"
            show-expand
            @update:options="loadItems"
        >
            <template v-slot:expanded-row="{ columns, item }">
                <tr>
                    <td :colspan="columns.length-2">
                        <p>Email: {{ item.email }}</p>
                        <v-spacer />
                        <p>Phone: {{ item.phone }}</p>
                    </td>
                    <td>
                        <v-btn @click="deleteReferral(item.id)">Delete</v-btn>
                    </td>
                </tr>
            </template>
            <template v-slot:item.status="props">
                <SendRewardModal 
                    v-if="props.item.sentReward === 'No' && props.item.affiliate != null"
                    :referralId="props.item.id"
                    @update="loadItems({ page: 0, itemsPerPage, query })"
                />
                <CompleteReferral
                    v-if="props.item.sentReward === 'No' && props.item.affiliate === null"
                    :referralId="props.item.id"
                />
                <v-btn variant="flat" v-else-if="props.item.sentReward === 'Yes'">Completed</v-btn>
            </template>
        </v-data-table-server>
    </v-container>
</template>

<style scoped>
.padded {
    margin-bottom: 1rem;
}

h2, h4 {
    padding: 1rem 0;
}

.reward {
    display: flex;
    align-items: center;
}

.copyable {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.button {
    margin-bottom: .5rem;
    margin-right: .5rem;
}

.referrals__complete-modal {
    padding: 24px;
}

.referrals__complete-modal-actions {
    display: flex;
    gap: 8px;
    margin-left: auto;
}
</style>